<template>
    <Card class="card-custom" style="height: 100%" v-if="player">
        <template #title>
            <h4>{{ $i18n.t('verification') }}</h4>
        </template>
        <template #content>
            <v-row>
                <v-col class="d-flex align-center justify-center flex-column" v-for="doc in docsArray" :key="doc.id">
                    <div class="mb-1">{{ doc.name }}</div>

                    <div class="image-container" v-if="doc.image && doc.status !== 3">
                        <img
                            :src="`${doc.image}`" />
                        <div class="after"
                            :class="doc.status === 2 ? 'after_verified' : doc.status === 3 ? 'after_rejected' : ''"
                            @click="docClick(doc)">
                            <v-icon style="color: var(--white);font-size: 24px;margin-top: 65px">search</v-icon>
                        </div>
                    </div>
                    <div class="d-flex align-center" v-if="!doc.image || doc.status === 3">
                        <div class="image-container image-container_no-photo">
                            <span class="">{{ $t('no_data_submited') }}</span>
                        </div>
                    </div>
                    <div class="font-weight-bold" style="color: var(--green-500)" v-if="doc.status === 2">{{
                $i18n.t('verified') }}</div>
                    <!-- <div class="font-weight-bold" style="color: var(--red-500)" v-if="player.doc1_verified === 3">{{ $i18n.t('rejected') }}</div> -->
                    <span class="d-flex align-center gap-1 mt-1" v-if="doc.image && !(doc.status === 3 || doc.status === 2)">
                        <Button 
                        @click="setVerificationStatus(doc, false)"
                        :disabled="loadingFlag" 
                        :loading="loadingFlag || !allowedSetVerif" 
                        :label="$t('reject')"
                          class="p-button-sm p-button-danger"
                          v-tooltip.top="!allowedSetVerif ? $t('no_permission') : $t('tooltip_reject_doc')"
                          />
                          <Button 
                          :disabled="loadingFlag" 
                          :loading="loadingFlag || !allowedSetVerif" 
                          v-tooltip.top="!allowedSetVerif ? $t('no_permission') : $t('tooltip_accept_doc')"
                          :label="$t('accept')"
                          @click="setVerificationStatus(doc, true)"
                          class="p-button-sm p-button-success" />
                        </span>
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <v-dialog v-model="imageDialog" content-class="br-16" :transition="false" max-width="700px" scrollable>
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text class="">
                        <div class="image-full">
                            <img v-if="selectedDoc.image"
                                :src="selectedDoc.image" />
                        </div>
                    </v-card-text>
                    <v-card-actions>
                                <span class="p-buttonset fw" v-if="selectedDoc.image && !(selectedDoc.status === 2)">
                                    <Button 
                                    @click="setVerificationStatus(selectedDoc, false)"
                                    :disabled="loadingFlag || !allowedSetVerif" 
                                    :loading="loadingFlag" 
                                    :label="$t('reject')"
                                    v-tooltip.top="!allowedSetVerif ? $t('no_permission') : $t('tooltip_reject_doc')" 
                                      icon="pi pi-close" class="p-button-sm p-button-danger" />
                                      <Button 
                                      :disabled="loadingFlag || !allowedSetVerif" 
                                      v-tooltip.top="!allowedSetVerif ? $t('no_permission') : $t('tooltip_accept_doc')" 
                                      :loading="loadingFlag" 
                                      :label="$t('accept')"
                                      @click="setVerificationStatus(selectedDoc, true)"
                                      icon="pi pi-chck" class="p-button-sm p-button-success" />
                                    </span>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </Card>
</template>

<script>
import Api from "@/components/api";
import {mapGetters} from 'vuex';
export default {
    name: 'PPVerification',
    props: {
        playerInfo: Object,
    },
    data() {
        return {
            imageDialog: false,
            imageDialogSelectedImg: null,
            player: null,
            loadingFlag: false,
            docsArray: [],
            selectedDoc: {
                id: null,
                name: null,
                image: null,
                status: null
            }
        }
    },
    computed: {
        ...mapGetters({
              permissions: 'roles/elementsPlayerPermission',
            }),
            allowedSetVerif() {
              if (this.permissions) {
                if (this.permissions.includes('verif_docs') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
    },
    created() {
        
    },
    mounted() {
        if (this.playerInfo) {
            this.player = this.playerInfo;
            this.id = this.playerInfo.id;
        }
    },
    watch: {
        'playerInfo': {
            handler(newVal) {
                this.player = this.playerInfo;
                this.getDocsArray()
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        getDocsArray() {
            if (this.playerInfo) {
            this.docsArray = [
                {
                    id: 1,
                    name: this.$t('face_card_photo'),
                    image: this.player.doc1,
                    status: this.player.document_verification.doc1_verified
                },
                {
                    id: 2,
                    name: this.$t('face_passport_photo'),
                    image: this.player.doc2,
                    status: this.player.document_verification.doc2_verified
                },
                {
                    id: 3,
                    name: this.$t('passport'),
                    image: this.player.doc3,
                    status: this.player.document_verification.doc3_verified
                },
        ]
        }
        },
        docClick(doc) {
            this.imageDialog = true;
            this.selectedDoc = doc;
        },
        async setVerificationStatus(doc, approved) {
            this.loadingFlag = true;
            try {
                await Api.setVerificationStatus(doc.id, approved, this.player.id);
                // window.location.reload();
                this.$emit('update-player-info');
                this.imageDialog = false;
                if (approved) {
                    this.$toast.add({
                        severity: 'success', summary: this.$t('doc_was_approved'), life: 4000,
                    })
                } else{
                    this.$toast.add({
                        severity: 'info', summary: this.$t('doc_was_rejected'), life: 4000,
                    })
                }
            } catch (error) {
                this.$toast.add({
                    severity: 'error', summary: this.$t('just_error'), life: 4000,
                })
            } finally{
                this.loadingFlag = false;
            }
            
        },
    },
}
</script>

<style lang="scss" scoped>
.image-full{
    // width: 100%;
    // height: 100%;
    flex: 0 0 650px;
    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
.image-container {
    display: flex;
    justify-content:center;
    align-items: center;
    position: relative;
    flex: 0 0 150px;
    width: 150px;
    height: 150px;
    //margin: 0 auto;
    margin: 0 5px;
    border-radius: 16px;
    overflow: hidden;
    img{
        max-width: 100%;
        max-height: 100%;
    }
    &_no-photo{
      flex: 0 0 110px;
      width: 110px;
      height: 110px;
      background-color: var(--gray-300);
    }
    .after{
      transition: all 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      color: #FFF;
    }
    &:hover{
      .after{
        opacity: 1;
        visibility: visible;
        background: rgba(0, 0, 0, .3);
        cursor: pointer;
        &_rejected {
          background: rgba(252, 81, 132, 0.3)   }
        &_verified{
            background: rgba(76, 175, 79, 0.3);
  
          }
      }
    }
  }
</style>