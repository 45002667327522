<template>
  <div>
    <v-data-table
        striped
        hover
        :items="$store.getters.affiliates"
        :headers="fields"
        style="margin-top: 10px"
        :loading="loadingFlag"
        :footer-props="{'items-per-page-options': [10, 25, 100, -1],'items-per-page-text': this.$i18n.t('pages')}"
        :no-data-text="$i18n.t('aff_not_found')"
        :loading-text="this.$t('loading') + '...'"
    >
      <template v-slot:item.id="{item, data}">
        <div>
          <router-link :to="`affiliate/${item.id}`" >
            {{item.id}}
          </router-link>
        </div>
      </template>
      <template v-slot:item.block="{item}">
        <!--
        <select class="custom-select" v-model="data.item.block" :style="data.item.block == 1?'color: red':'color: green'">
            <option v-for="option in options" :style="option.value == 1?'color: red':'color: green'" :value="option.value">
                {{option.text}}
            </option>
        </select>
        -->
        <div v-if="item.block === 0">
          {{ $i18n.t('confirmed') }}
        </div>
        <div v-if="item.block === 1">
          {{ $i18n.t('check') }}
        </div>
      </template>
      <template v-slot:item.balance="{item}">
        <div>
          {{Number.parseInt(item.balance)}} <b style="font-weight: normal">{{$store.getters.currency}}</b>
        </div>
      </template>
      <template v-slot:item.regDate="{item}">
        <div style="white-space: nowrap">
          {{new Date(item.regDate).toLocaleString()}}
        </div>
      </template>
    </v-data-table>


  </div>
</template>

<script>
  import StatisticTab from "./Modal_StatisticTab";
  import OffersModalTab from "./Modal_OffersTab";
  import PlayersTab from "./Modal_PlayersTab";
  import WithdrawTab from "./Modal_WithdrawTab"
  export default {
    name: "AffiliatesTable",
    components:{
      WithdrawTab,
      StatisticTab,
      OffersModalTab,
      PlayersTab
    },
    data(){
      return{
        selectedTab: null,
        //Аффилейт, баланс, email, дата регистрации, статус

        fields: [
          { value: 'id', text: 'ID', sortable: true },
          { value: 'balance', text:  this.$i18n.t('balance'), sortable: true },
          { value: 'email', text: 'Email', sortable: true },
          { value: 'created_at', text:  this.$i18n.t('registered'), sortable: true, sort: function (a, b) {
              return new Date(a) - new Date(b);
            }  },
          { value: 'traffic_source', text:  this.$i18n.t('traffic_source'), sortable: true }
        ],
        loadingFlag: false,
        loadingModalFlag:false,
        options:[
          {value: 0, text:  this.$i18n.t('active')},
          {value: 1, text:  this.$i18n.t('not_active')}
        ],
        affiliateModalFlag: false,
        modalPlayer:{},
        filters:{
          affiliate: '',
          status: '',
          minBalance: '',
          maxBalance: '',
          minDate:'',
          maxDate:'',
        }
      }
    },

    methods:{
      rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.block === 1){
          return 'table-danger'
        }else{
          return ''
        }
      },

    }
  }
</script>

<style scoped>

</style>
