import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isCallInProgress)?_c(VBtn,{style:({backgroundColor: this.isRegistered ? 'var(--green-success)' : 'var(--main-flamingo)'}),attrs:{"icon":"","small":""},on:{"click":_vm.call}},[_c(VIcon,{staticStyle:{"font-size":"18px"},attrs:{"color":"white"}},[_vm._v("phone")])],1):_vm._e(),(_vm.isCallInProgress)?_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('call_in_progress'))+" ")]):_vm._e(),(!_vm.isRegistered)?_c(VDialog,{attrs:{"max-width":"fit-content","content-class":"br-16"},model:{value:(_vm.signDialog),callback:function ($$v) {_vm.signDialog=$$v},expression:"signDialog"}},[_c(VCard,{staticStyle:{"min-width":"320px"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$i18n.t('authorization'))+" ")]),_c(VCardText,[_c(VTextField,{staticClass:"mb-2",attrs:{"hide-details":"","dense":"","outlined":"","color":"var(--main-flamingo)","label":_vm.$i18n.t('login')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{staticClass:"mb-2",attrs:{"hide-details":"","dense":"","outlined":"","color":"var(--main-flamingo)","label":_vm.$i18n.t('password'),"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{staticStyle:{"text-transform":"uppercase"},attrs:{"color":"main-btn","loading":_vm.isConnecting},on:{"click":_vm.connect}},[_vm._v(" "+_vm._s(_vm.$i18n.t('sign_in'))+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }