<template>
  <v-main style="min-height: 100%">
    <v-row>
      <v-col>
        <h2 class="d-flex align-center justify-center pa-3 pb-0" style="font-size: 24px; font-weight: bold; color: var(--main-flamingo);">
          <div class="mr-1">{{ $i18n.t('bonuses') }}</div>
          
        </h2>
      </v-col>
    </v-row>
    <hr>
    <v-data-table
        :items="depBonuses"
        :headers="headersDep"
        :loading="loading"
        :loading-text="this.$t('loading') + '...'"
        striped
        hover
        dense
        :footer-props="{
          'items-per-page-options': [10, 25, 100],
          'items-per-page-text': $i18n.t('pages'),
          
        }" 
    >
      <template v-slot:top>
        <v-row align="center" class="mb-2 px-3">
          <v-col cols="12" class="d-flex align-center">
            <h3> {{ $i18n.t('dep_bonuses') }}</h3>
            <v-spacer></v-spacer>
            <v-btn @click="createDepBonusDialog = true" class="main-btn" style="text-transform: none">
              <v-icon>add</v-icon>
              {{ $i18n.t('dep_bonus_create') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.type="{ item }">
        <div>
          {{ item.type === 'welcome' ? $t('welcome') : item.type === 'free_rounds' ? $t('free_rounds') : item.type }}
        </div>
        
      </template>
      <template v-slot:item.max_withdraw_amount="{ item }">
        {{ item.max_withdraw_amount }} {{ item.currency }}
      </template>
      <template v-slot:item.min_dep="{ item }">
        {{ item.min_dep }} {{ item.currency }}
      </template>
      <template v-slot:item.deposit_type="{ item }">
        {{ item.deposit_type === 'first' ? $i18n.t('first') : $i18n.t('next') }}
      </template>
      <template v-slot:item.wager="{ item }">
        x{{ item.wager }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="var(--red)" small dark @click="toggleConfirmDeleteDialog(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <hr>
    <v-data-table
        striped
        hover
        dense
        :items="vendorBonuses"
        :headers="headersVendor"
        :loading="loading"
        :loading-text="this.$t('loading') + '...'"
        :footer-props="{
          'items-per-page-options': [10, 25, 100],
          'items-per-page-text': $i18n.t('pages'),
          
        }" 
    >
      <template v-slot:top>
        <v-row align="center" class="mb-2 px-3">
          <v-col cols="12" class="d-flex align-center">
            <h3>{{ $i18n.t('spin_bonuses') }}</h3>
            <v-spacer></v-spacer>
            <v-btn @click="createVendorBonusDialog = true" class="main-btn" style="text-transform: none">
              <v-icon>add</v-icon>
              {{ $i18n.t('spin_bonus_create') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      
      <template v-slot:item.type="{ item }">
        <div>
          {{ item.type === 'welcome' ? $t('welcome') : item.type === 'free_rounds' ? $t('free_rounds') : item.type }}
        </div>
        
      </template>
      <template v-slot:item.wager="{ item }">
        x{{ item.wager }}
      </template>
      <template v-slot:item.max_withdraw_amount="{ item }">
        {{ item.max_withdraw_amount }} {{ item.currency }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ item.price }} {{ item.currency }}
      </template>
      <template v-slot:item.bet="{ item }">
        {{ item.bet }} {{ item.currency }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="var(--red)" small dark @click="toggleConfirmDeleteDialog(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- Delete Window -->
    <v-dialog
        v-model="confirmDeleteDialog"
        max-width="fit-content"
        scrollable
        :persistent="formLoading"
       
        content-class="br-16"
    >
      <v-card style="max-width: 400px">
        <v-card-title class="font-weight-bold">
          <span>{{ $i18n.t('bonus_delete') }}</span>
          <span class="c-red">{{ $i18n.t('deleting_bonus') }}</span>
        </v-card-title>
        <v-card-text class="pb-0 pt-2">
          
          <!-- {{ deletingBonus }} -->
          <v-text-field
          v-if="deletingBonus.game_id"
                v-model="deletingBonus.game_id"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('game') + ' ' + '(id)'"
            />
          <v-text-field
          v-if="deletingBonus.bet"
                v-model="deletingBonus.bet"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('bet')"
                :suffix="deletingBonus.currency"
            />
          
            <v-text-field
          v-if="deletingBonus.type"
          :value="filteredType"      
          readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('type')"
            />
          <v-text-field
          v-if="deletingBonus.currency"
                v-model="deletingBonus.currency"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('currency')"
            />
            <v-text-field
          v-if="deletingBonus.expire_in_days"
                v-model="deletingBonus.expire_in_days"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('expire_in_days')"
            />
          <v-text-field
          v-if="deletingBonus.lines"
                v-model="deletingBonus.lines"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('lines')"
            />
          <v-text-field
          v-if="deletingBonus.wager"
                v-model="deletingBonus.wager"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('wager')"
                prefix="x"
            />
          <v-text-field
          v-if="deletingBonus.bonus_percent"
                v-model="deletingBonus.bonus_percent"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('bonus_percent')"
                suffix="%"
            />
            <v-text-field
            v-if="deletingBonus.deposit_source"
                  v-model="deletingBonus.deposit_source"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                  color="var(--main-flamingo)"
                  :label="$i18n.t('deposit_source')"
              />
          <v-text-field
          v-if="deletingBonus.count"
                v-model="deletingBonus.count"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('spins')"
            />
          <v-text-field
          v-if="deletingBonus.mpl"
                v-model="deletingBonus.mpl"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('multiplier')"
            />
        </v-card-text>
        <v-card-actions>
          <v-col style="text-align: right" class="pa-0">
            <v-btn style="text-transform: none" class="main-btn" dark :loading="formLoading" @click="deleteBonus">
              <v-icon>delete</v-icon>
              {{ $i18n.t('delete') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Create Deposit Bonus -->
    <v-dialog v-model="createDepBonusDialog" content-class="br-16" scrollable max-width="fit-content" :persistent="formLoading">
      <v-card style="min-width: 400px">
        <v-card-title  class="font-weight-bold">
          {{ $i18n.t('dep_bonus_creation') }}
        </v-card-title>
        <v-card-text class="pb-0 pt-2">
          <v-form ref="depForm" v-model="depBonusValid">
            <v-text-field
                v-model="depBonus.min_dep"
                :rules="[v => v > 0 || $i18n.t('min_dep_limit')]" outlined dense  color="var(--main-flamingo)"
                :label="this.$t('min_dep')"
                required
                :suffix="depBonus.currency"
                hide-details
                class="mb-3"
            ></v-text-field>
            <v-text-field
                v-model="depBonus.max_dep"
                :label="this.$t('max_dep')"
                outlined
                dense  
                color="var(--main-flamingo)"
                :suffix="depBonus.currency"
                hide-details
                class="mb-3"
            ></v-text-field>
            <v-text-field
                v-model="depBonus.bonus_percent"
                :rules="[v => v > 0 || $i18n.t('min_bonus_limit')]" outlined dense  color="var(--main-flamingo)"
                :label="$i18n.t('bonus_percent')"
                required
                suffix="%"
                hide-details
                class="mb-3"
            ></v-text-field>
            <v-text-field
                v-model="depBonus.wager"
                :label="this.$t('wager')"
                required
                outlined dense  
                color="var(--main-flamingo)"
                hide-details
                class="mb-3"
            ></v-text-field>
            <v-select
                v-model="depBonus.type"
                :items="[this.$t('welcome'), 'next_deposit', 'no_deposit']"
                color="var(--main-flamingo)"
                :label="this.$t('type')"
                dense 
                outlined
                hide-details
                class="mb-3"
            ></v-select>
            <v-text-field
                v-model="depBonus.deposit_source"
                :label="this.$t('deposit_source')"
                outlined
                dense  
                color="var(--main-flamingo)"
                hide-details
                class="mb-3"
            ></v-text-field>
            <v-text-field
                v-model="depBonus.expire_in_days"
                :label="this.$t('expire_in_days')"
                outlined
                dense  
                color="var(--main-flamingo)"
                hide-details
                class="mb-3"
            ></v-text-field>
            <v-select
                v-model="depBonus.currency"
                :items="currencies"
                :label="this.$t('currency')"
                outlined
                dense  
                color="var(--main-flamingo)"
                hide-details
                class="mb-3"
            ></v-select>
            
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                :disabled="!depBonusValid"
                :loading="formLoading"
                @click="createDepBonus"
                class="success-btn"
            >
              {{ $i18n.t('save') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Create Spins Bonus -->
    <v-dialog v-model="createVendorBonusDialog" scrollable content-class="br-16" max-width="fit-content" :persistent="formLoading">
      <v-card style="min-width: 400px">
        <v-card-title class="font-weight-bold">
          {{ $i18n.t('spin_bonus_creation') }}
        </v-card-title>
        <v-card-text class="pb-0 pt-2">
          <v-form
              ref="depForm"
              v-model="vendorBonusValid"
          >
            <v-text-field
                v-model="vendorBonus.game_id"
                :rules="[v => v > -1 || $i18n.t('choose_game')]" outlined dense  color="var(--main-flamingo)"
                :label="$i18n.t('game') + ' ' + '(id)'"
                hide-details
                class="mb-3"
            >
            </v-text-field>

            <v-text-field
                v-model="vendorBonus.bet"
                :rules="[v => v > 0 || $i18n.t('min_lim_bet')]" outlined dense color="var(--main-flamingo)"
                :label="$i18n.t('bet')"
                hide-details
                class="mb-3"
                required
                :suffix="vendorBonus.currency"
            >
            </v-text-field>
            <v-select
                v-model="vendorBonus.currency"
                :items="currencies"
                hide-details
                class="mb-3"
                :label="this.$t('currency')"
                outlined
                dense  color="var(--main-flamingo)"
            ></v-select>
            <v-text-field
                v-model="vendorBonus.lines"
                :rules="[v => v > 0 || $i18n.t('lines_greater_than_zero')]" outlined dense  color="var(--main-flamingo)"
                :label="$i18n.t('lines')"
                hide-details
                class="mb-3"
                required
            >
            </v-text-field>
            <v-select
                v-model="vendorBonus.type"
                :items="[this.$t('free_rounds'),this.$t('welcome')]"
                :label="this.$t('type')"
                hide-details
                class="mb-3"
                outlined
                dense  color="var(--main-flamingo)"
            ></v-select>
            <v-text-field
                v-model="vendorBonus.count"
                :rules="[v => v > 0 || $i18n.t('spins_greater_than_zero')]" outlined dense  color="var(--main-flamingo)"
                :label="$i18n.t('spins')"
                hide-details
                class="mb-3"
                required
            >
            </v-text-field>
            <v-text-field
                v-model="vendorBonus.mpl"
                :rules="[v => v > 0 || $i18n.t('multiplier_greater_than_zero')]" outlined dense  color="var(--main-flamingo)"
                :label="$i18n.t('multiplier')"
                hide-details
                class="mb-3"
                required
            >
            </v-text-field>
          </v-form>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!vendorBonusValid"
              :loading="formLoading"
              style="text-transform: none"
              @click="createVendorBonus"
              
              class="success-btn"
          >
            {{ $i18n.t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import {mapGetters} from 'vuex';
import Api from "@/components/api";

export default {
  name: "Bonuses",
  computed: {
    ...mapGetters({
      depBonuses: 'bonuses/depBonuses',
      vendorBonuses: 'bonuses/vendorBonuses',
      currencies: 'getCurrencies',
    }),
    filteredType(){
      return this.deletingBonus.type === 'welcome' ? this.$t('welcome') : this.deletingBonus.type === 'free_rounds' ? this.$t('free_rounds') : this.deletingBonus.type;
    }
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      headersDep: [
        {value: 'id', text: 'ID', sortable: true},
        {value: 'type', text: this.$i18n.t('type'), sortable: true},
        {value: 'min_dep', text: this.$i18n.t('min_dep'), sortable: true},
        {value: 'max_dep', text: this.$i18n.t('max_dep'), sortable: true},
        {value: 'bonus_percent', text: this.$i18n.t('bonus_percent'), sortable: true},
        {value: 'wager', text: this.$i18n.t('wager'), sortable: true},
        {value: 'deposit_source', text: this.$i18n.t('deposit_source'), sortable: true},
        {value: 'expire_in_days', text: this.$i18n.t('expire_in_days'), sortable: true},
        {value: 'currency', text: this.$i18n.t('currency'), sortable: true},
        {value: 'actions', text: this.$i18n.t('actions'), sortable: false},
      ],

      headersVendor: [
        {value: 'id', text: 'ID', sortable: true},
        {value: 'game_id', text: this.$i18n.t('game') + ' ' +'(id)', sortable: true},
        {value: 'type', text: this.$i18n.t('type'), sortable: true},
        {value: 'lines', text: this.$i18n.t('lines'), sortable: true},
        {value: 'count', text: this.$i18n.t('spin'), sortable: true},
        {value: 'bet', text: this.$i18n.t('bet'), sortable: true},
        {value: 'mpl', text: this.$i18n.t('multiplier'), sortable: true},
        {value: 'wager', text: this.$i18n.t('wager'), sortable: true},
        {value: 'expire_in_days', text: this.$i18n.t('expire_in_days'), sortable: true},
        {value: 'currency', text: this.$i18n.t('currency'), sortable: true},
        {value: 'actions', text: this.$i18n.t('actions'), sortable: false},
      ],
      createDepBonusDialog: false,
      createVendorBonusDialog: false,
      depBonusDialog: false,
      vendorBonusDialog: false,
      depBonus: {
        min_dep: 100,
        max_dep: 500,
        bonus_percent: 0,
        currency: 'USD',
        wager: 0,
        type: '',
        deposit_source: '',
        is_enabled: true,
        expire_in_days: 0,
      },
      depBonusValid: true,
      vendorBonus: {
        type: '',
        is_enabled: true,
        wager: 0,
        currency: 'USD',
        expire_in_days: 0,
        game_id: 0,
        lines: 5,
        count: 20,
        bet: 10,
        mpl: 1,
      },
      vendorBonusValid: true,
      allGames: [],
      deletingBonus: {},
      confirmDeleteDialog: false,
    }
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch('bonuses/getDepBonuses');
    await this.$store.dispatch('bonuses/getSpinsBonuses');
    this.loading = false;
  },
  methods: {
    async createDepBonus() {
      if (this.depBonusValid) {
        this.formLoading = true;
        let {bonus_id} = await Api.createDepBonus(this.depBonus);
        this.createDepBonusDialog = !bonus_id;
        if (bonus_id) {
          await this.$store.dispatch('bonuses/getDepBonuses');
          this.depBonus = {
            min_dep: 100,
            max_dep: 500,
            bonus_percent: 0,
            currency: '',
            wager: 0,
            type: '',
            deposit_source: '',
            is_enabled: true,
            expire_in_days: 0,
          };
        }
        this.formLoading = false;
      }
    },


    async createVendorBonus() {
      if (this.vendorBonusValid) {
        this.formLoading = true;
        let {bonus_id} = await Api.createVendorBonus(this.vendorBonus);
        this.createVendorBonusDialog = !bonus_id;
        if (bonus_id) {
          await this.$store.dispatch('bonuses/getSpinsBonuses');
          this.vendorBonus = {
            type: '',
            is_enabled: true,
            wager: 0,
            currency: '',
            expire_in_days: 0,
            lines: 5,
            count: 20,
            bet: 10,
            mlp: 1,
          };
        }
        this.formLoading = false;
      }
    },

    toggleConfirmDeleteDialog(bonus) {
      this.deletingBonus = bonus;
      this.confirmDeleteDialog = true;
    },
    async deleteBonus() {
      this.formLoading = true;
      let res = false;
      if ('mpl' in this.deletingBonus) {
        res = await Api.deleteVendorBonus(this.deletingBonus.id);
      } else if ('id' in this.deletingBonus) {
        res = await Api.deleteDepBonus(this.deletingBonus.id);
      }
      this.confirmDeleteDialog = !res;
      this.formLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
