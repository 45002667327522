<template>
    <Card class="card-custom form-block _full" style="height: 100%" v-if="player">
        <template #title>
            <h4>{{ $i18n.t('account_management') }}</h4>
        </template>
        <template #content>
            <div class="space-y-1" v-if="(player.block === 0 && !player.is_active) || player.block === 2"> 
                <span v-if="player.block === 0">
                    <h4 class="c-text white-space title-input">
                    {{ $t('blocked_until') }}: 
                    </h4>
                    <div class="d-flex align-center wrapper-input justify-end">
                        <div v-if="player.block !== null"
                        :style="player.block === 0 && player.is_active ? 'color: var(--green-500)' : player.block === 2 ? 'color: var(--red-500)' : 'color: var(--yellow-500)'"
                        class="mr-2 font-weight-bold">{{ playerBlockStatus }}</div>
                    <span class="d-flex flex-column">
                        {{ new Date(player.block_end).toLocaleString() }}
                    </span>
                    </div>
                </span>
                <span v-else-if="player.block === 2">
                    <h4 class="c-text white-space title-input">
                    {{ $t('blocked') }}: 
                    </h4>
                    <div class="d-flex align-center wrapper-input justify-end">
                        <div
                        style="color: var(--red-500)"
                        class="mr-2 font-weight-bold">{{ playerBlockStatus }}</div>
                    </div>
                </span>
                <span class="justify-center">
                    <Button :label="$t('unblock')" 
                    :disabled="!allowedPermanentlyBlock || !allowedTempBlock"
                    v-tooltip.left="!allowedPermanentlyBlock || !allowedTempBlock ? $t('no_permission') : $t('tooltip_unblock_forever')" 
                        @click="unblockUser" :loading="blockLoading" icon="pi pi-unlock"
                        class="p-button-success p-button-sm" />
                </span>
                </div>
            <div class="space-y-1" v-else>
            <span> 
                <h4 class="c-text white-space title-input">
                {{ $t('block_forever') }}: 
                </h4>
                <div class="d-flex align-center wrapper-input justify-end">
                <span class="d-flex flex-column">
                    <Button 
                    :label="$t('block_forever')" 
                    v-if="player.block === 0" @click="blockUser(true)"
                    :disabled="!allowedPermanentlyBlock"
                    v-tooltip.left="!allowedPermanentlyBlock ? $t('no_permission') : $t('tooltip_block_forever')" 
                        :loading="blockLoading" icon="pi pi-ban" class="p-button-danger p-button-sm button-management" />
                </span>
                </div>
                </span>
                <Divider></Divider>
            <span> 
                <h4 class="c-text white-space title-input">
                {{ $t('temporary_block') }}: 
                </h4>
                <div class="d-flex align-center wrapper-input justify-end">
                    <span>
                        <div class="p-inputgroup button-management">
                            <Calendar 
                            class=""
                            :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :minDate="today" id="icon"
                            dateFormat="mm.dd.yy" 
                            v-model="banDateTime" selectionMode="single" />
                            <Button 
                            v-tooltip.left="!allowedTempBlock ? $t('no_permission') : $t('tooltip_temp_block')" 
                            @click="blockUser(false, true)" 
                            :disabled="!banDateTime || !allowedTempBlock"
                                :loading="blockLoading" icon="pi pi-ban" class="p-button-danger p-button-sm" />
                        </div>
                    </span>
                </div>
                </span>
                <Divider></Divider>
            <span> 
                <h4 class="c-text white-space title-input">
                {{ $t('operations') }}: 
                </h4>
                <div class="d-flex align-center wrapper-input justify-end">
                    <Button 
                    v-tooltip.left="!allowedChangeBalance ? $t('no_permission') : $t('tooltip_change_balance')" 
                    :disabled="!allowedChangeBalance"
                    :label="$t('change_balance')" @click="toChangeBalanceModal" icon="pi pi-money-bill"
                        class="p-button-success p-button-sm button-management" />
                </div>
                </span>
            </div>
        </template>
        <template #footer>
            <v-dialog content-class="br-16" v-model="changeBalanceModal" max-width="500px">
                <v-card>
                  <v-card-title class="pb-0 justify-center"> 
                    <h3>{{ $i18n.t('change_balance') }}</h3>
                    <close-modal-button 
                    @close-modal="changeBalanceModal = false"
                    />
            </v-card-title>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
                    <v-card-text class="pb-0 pt-1 text-left space-y-2">
                        <Message v-if="errorCount" severity="error" :closable="false">

                            {{ $t('not_enough_balance') }}
                        </Message>
                        <Message icon="null" severity="info" :closable="false" v-if="player && player.balance">
                            <span class="c-text">
                               {{$t('available_balance')}}:
                            </span>
                            <button type="button" @click="addBalanceAmount = player.balance">
                                {{ (player.balance ? player.balance : 0) }} {{ player.currency }}
                            </button>
                        </Message>
                                    <ValidationProvider
                            :name="$t('amount')"
                            rules="required|min_value:1"
                            v-slot="{ errors, validate, validated }"
                          > 
                            <h4 class="c-text white-space">{{ $t('enterAmount') }}:</h4>
                            <div class="d-flex flex-column">
                              <!-- :minFractionDigits="2" -->
                              <InputNumber 
                              locale="en-US"
                              class="fw"
                              :class="{ 'p-invalid' : errors.length > 0 }"
                              mode="currency" 
                              :currency="player.currency" 
                              currencyDisplay="code" 
                              :placeholder="$t('enterAmount')" 
                              v-model="addBalanceAmount" 
                              showButtons  
                              :step="1"
                              :min="0"
                              />
                              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                                    <ValidationProvider
                            :name="$t('comment')"
                            rules="required"
                            v-slot="{ errors, validate, validated }"
                          > 
                            <h4 class="c-text white-space">{{ $t('comment_change_balance') }}<span class="c-red">*</span>:</h4>
                            <div class="d-flex flex-column">
                              <!-- :minFractionDigits="2" -->
                              <Textarea v-model="commentToChangeBalance" 
                            :autoResize="true" rows="5" class="fw" />
                              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </v-card-text>
                    <v-card-actions>
                        <div slot="modal-footer" style="width: 100%;">
                                    <span class="p-buttonset fw">
                                        <Button 
                                        @click="handleSubmit(validate, false)"
                                        :loading="loadingFlag"
                                        :label="$t('debit')"
                                          icon="pi pi-close" class="p-button-sm p-button-danger" />
                                          <Button 
                                          :loading="loadingFlag"
                                          :label="$t('credit')"
                                          @click="handleSubmit(validate, true)"
                                          icon="pi pi-chck" class="p-button-sm p-button-success" />
                                        </span>
                        </div>
                    </v-card-actions>
                </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </Card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: 'PPManagement',
    props:{
        playerInfo: Object,
    },
    data() {
        return {
            addBalanceAmount: 0,
            commentToChangeBalance: '',
            errorCount: false,
            changeBalanceModal: false,
            loadingFlag: false,
            blockLoading: false,
            banDateTime: null,
            id: null,
            player: null,
        }
    },
    mounted() {
        if (this.playerInfo) {
            this.player = this.playerInfo;
            this.id = this.playerInfo.id;
        }
    },
    watch:{
        'playerInfo'(){
            this.player = this.playerInfo;
        this.id = this.playerInfo.id;
        }
    },
    computed: {
            ...mapGetters({
              permissions: 'roles/elementsPlayerPermission',
            }),
            allowedTempBlock() {
              if (this.permissions) {
                if (this.permissions.includes('temporary_block') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
            allowedChangeBalance() {
              if (this.permissions) {
                if (this.permissions.includes('change_balance') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
            allowedPermanentlyBlock() {
              if (this.permissions) {
                if (this.permissions.includes('permanently_block') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
        today(){
            var today = new Date();
        today.setDate(today.getDate() + 1);
        return today;
        },
        playerBlockStatus() {
      if (this.player.block === 0 && !this.player.is_active) {
        return this.$i18n.t('temporary_blocked');
      } else if (this.player.block === 0) {
        return this.$i18n.t('active');
      } else if (this.player.block === 2) {
        return this.$i18n.t('forever_blocked');
      } else {
        return '';
      }
    },
    },
    methods: {
        handleSubmit(valid, state) {
            valid().then(valid => {
                if (valid) {
                    this.errorCount = false;
                    if (this.player.balance < this.addBalanceAmount && !state) {
                        this.errorCount = true;
                    } else{
                        this.changeBalance(state);
                    }
                }
            })
        },
        toChangeBalanceModal() {
            this.changeBalanceModal = true;
            this.$nextTick(() => {
                    this.$refs.observer.reset();
                  })
        },
        async unblockUser() {
            this.blockLoading = true;
            let res = await this.$store.dispatch('blockUser', { user_id: this.id, block: 0 });
            if (res) {
                this.$root.$emit('updatePlayerInfo');
                this.player.block = 0;
                this.player.is_active = true;
            } else{
                this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          })
            }
            this.blockLoading = false;
        },
        async blockUser(foreverFlag = false, temporary = false) {
            let banDateTime
            if (this.banDateTime) {
                const date = new Date(this.banDateTime);
                const formattedDates = date.toLocaleDateString('en-CA');
                banDateTime = formattedDates;
            }
            if (!foreverFlag && this.banDateTime === null) return;
            this.blockLoading = true;
            let blockValue;
            if (foreverFlag) {
                blockValue = 2;
            } else {
                blockValue = 0;
            }
            try {
                const userTimezone = moment.tz.guess();
                const offset = moment.tz(userTimezone).format('Z');
                await this.$store.dispatch('blockUser', {
                    user_id: this.id,
                    block: blockValue,
                    block_end: foreverFlag ? null : banDateTime,
                    tz_offset: offset,
                });
                
                this.$emit('update-player-info');
                this.player.block = foreverFlag ? 2 : 0;
                this.player.is_active = false
                this.player.block_end = banDateTime;
                this.banDateTime = null;
            } catch (error) {
                
                this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          })
            } finally{
                this.blockLoading = false;
            }
        },
        async changeBalance(increaseFlag = false) {
      this.loadingFlag = true;
      try {
          await this.$store.dispatch(
              'createCompensation',
              {
                amount: (increaseFlag ? 1 : (-1)) * this.addBalanceAmount,
                id: this.id,
                text: this.commentToChangeBalance,
              }
          );
              this.changeBalanceModal = false;
              this.addBalanceAmount = 0;
              this.$toast.add({
                  severity: 'success', summary: this.$t("success_balance_changed"), life: 4000
                })
              this.$root.$emit('updatePlayerInfo');
              await this.$store.dispatch('awaitGetCompensation', {id: this.id})
              this.$emit('update-player-info');
              this.$emit('trigger-loading-compensation');
              this.changeBalanceModal = false;
              this.addBalanceAmount = 0;
              this.commentToChangeBalance = '';
      } catch (error) {
        if (error.response && error.response.status === 404) {
            this.$toast.add({
            severity: 'error', summary: this.$t('user_not_found'), 
            life: 4000
          })
        } else if (error.response && error.response.status === 400) {
            this.$toast.add({
            severity: 'error', summary: this.$t('invalid_amount'), 
            life: 4000
          })
        } else if (error.response && error.response.status === 403) {
            this.$toast.add({
            severity: 'error', summary: this.$t('no_permission'), 
            life: 4000
          })
        } else{
            this.$toast.add({
              severity: 'error', summary: this.$t("just_error"), 
              detail: error.message,
              life: 4000
            })
        }
      } finally{
        this.loadingFlag = false;
      }
      
    },
    },
    
}
</script>

<style lang="scss" scoped></style>