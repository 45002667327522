<template>
  <div class="text-left c-text">
      <div v-if="player.hash !== null">
       
        <Accordion class="br-0" v-if="player.finger !== null">
          <AccordionTab >
            <template #header>
              <div class="d-flex flex-column">
                <span>
                  {{ $t('all_fingerprint_parameters') }}
                  
                </span>
                <div class="c-text">
                  <div style="font-size: 18px" class="my-1">
                    <span>{{ $i18n.t('fingerprint_hash') }}:<b> {{ player.hash }}</b></span>
                    <button class="outline-none" type="button" @click.stop="copy(player.hash)">
                      <i class="pi pi-copy ml-1">
                        
                      </i>
                    </button>
                  </div>
                  <h3 class="">
                    {{ $i18n.t('multi_accounts_found') }}: 
                    <b style="font-size: 20px">{{ multiAccounts.count }}</b>
                  </h3>
                  <!-- v-if="multiAccounts.count > 0" -->
                  <Button  
                  :disabled="loadingFlag || !allowedMultiacssBlock"
                  v-tooltip.top="!allowedMultiacssBlock ? $t('no_permission') : $t('tooltip_multi_block')" 
                  @click="blockAll" 
                  :label="$t('block_all')" 
                  icon="pi pi-hammer" class="p-button-sm p-button-danger" />
                </div>
              </div>
            </template>
            <div v-for="(value, key) in player.finger">
              <v-row>
                <v-col class="text-left font-weight-bold" style="max-width: 200px">
                  {{ $t(key) }}
                </v-col>
                <v-col style="max-width: calc(100vw - 200px);overflow: hidden;text-overflow: ellipsis;word-break: break-all">
                  {{ value }}
                </v-col>
              </v-row>
              <Divider />
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <div v-else>
        {{ $i18n.t('fingerprint_not_available') }}
      </div>
      <div>
        
      <DataTable 
        :value="multiAccounts.multiaccs" 
        data-key="id"
        :rows="10"
        :loading="loadingFlag" 
        :paginator="true"
        :scrollable="true" 
          class="p-datatable-sm"
          >
          <template #empty>
           <div class="font-weight-bold">{{ $i18n.t('no_multi') }} </div>
        </template>
          <Column field="id" header="Id" :showFilterMenu="false" :styles="{'max-width':'25rem'}">
            <template #body="{data}">
              <router-link class="c-main-flamingo font-weight-bold" :to="`/player/${data.id}`" >ID {{ data.id }}</router-link>
          </template>
          </Column>
          <Column field="username" :header="$t('username')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div> {{ data.username }}</div>
          </template>
          </Column>
          <Column field="email" :header="$t('email')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div> {{ data.email }}</div>
          </template>
          </Column>
          <Column field="reg_ip" :header="$t('reg_ip')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div> {{ data.reg_ip }}</div>
          </template>
          </Column>
          <Column field="similarity" :header="$t('similarity')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div>
                {{ data.similarity ? data.similarity : 0 }}
              </div>
          </template>
          </Column>
          
        </DataTable>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


  export default {
    name: "MultiAccountsTab",
    props: {
      player: {
        required: true,
        default: null,
      }
    },
    data() {
      return {
        loadingFlag: false,
      }
    },
    computed: {
      ...mapGetters({
        multiAccounts: 'getMultiaccs',
        permissions: 'roles/elementsPlayerPermission',
      }),
            allowedMultiacssBlock() {
              if (this.permissions) {
                if (this.permissions.includes('multiaccs_block') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
    },
    async mounted() {
      this.loadingFlag = true;
      try {
        await this.$store.dispatch('getMultiAccounts', {id: this.player.id});
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
      } finally{
        
        this.loadingFlag = false;
      }
    },
    methods:{
      copy(hash) {
        navigator.clipboard.writeText(hash);
        this.$toast.add({ severity: 'success', summary: this.$t('copied_to_clipboard'), life: 4000 });
      },
      async blockAll(){
        this.loadingFlag = true;
        const allUsers = this.multiAccounts.count;
        const res = await this.$store.dispatch('blockAllUsers', allUsers);
        if (res) {
        this.loadingFlag = false;
        this.$toast.add({ severity: 'success', summary: this.$t('all_multi_get_ban'), life: 4000 });
        } else{
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
          }
        this.loadingFlag = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
.header-title{
  display: flex;
  margin-bottom: 4px;
  flex-direction: column;
  justify-content:center;
  align-items: center;
}
.multi-grid{
  display: flex;
  margin: -5px!important;
  flex-wrap: wrap;
  justify-content: center;
  &__column{
    padding: 6px 8px!important;
    flex: 0 1 33.3333%;
  }
  &__item{
    white-space: nowrap;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    span{

    }
    a{
      margin-left: 5px;
    }
  }
  &__content{
    display: grid;
    grid-template-columns: 1fr 300px;
    justify-content:center;
    align-items: center;
    a{
      justify-self: flex-end;
      display: inline-block;
    }
  }
}
.custom-overlay{
  background-color: rgb(255 255 255 / 0.3)!important;
      backdrop-filter: blur(10px);
}
</style>
