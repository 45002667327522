<template>
  <div>
    <Card class="br-0">
      <!-- <template #title>
        <div class="d-flex justify-center">
          <v-spacer class="column-three"></v-spacer>
          <h4 class="column-three" st>{{ $i18n.t('search') }}</h4>
          <span class="column-three p-buttonset justify-end d-flex">
            <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
            <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')" icon="pi pi-search" class="p-button-sm p-button-success" />
        </span>
        </div>
    </template> -->
          <template #content>  
            <v-chip-group>
              <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" 
              >
                <template>
                {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                </template>
                <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
              </v-chip>
            </v-chip-group>
      </template>
      </Card>
        <DataTable :value="getCurrentLogs.items" 
      :rows="getCurrentLogs.size"
      :loading="loadingFlag" 
      filterDisplay="row"
        class="p-datatable-sm"
        >
        <template #empty>
         <div class="font-weight-bold text-center">{{ $i18n.t('trans_not_found') }} </div>
      </template>

        <Column :field="isAgentPlayer ? 'id' : 'transaction_id'" :header="isAgentPlayer ? 'ID' :$t('transaction_id')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div class="font-weight-bold" v-if="isAgentPlayer">
              {{ data.id }}
            </div>
            <v-col v-else class="pa-0" style="color: var(--main-flamingo);cursor:pointer;" @click="getTransactionItem(data)">
              {{ data.transaction_id }}
            </v-col>
          </template>
          <template #filter>
            <!-- <InputText v-if="isAgentPlayer" type="text" @input="debounceFilters" 
            v-model="filters.id" 
            class="p-inputtext-sm" 
            :placeholder="$t('transaction_id')" /> -->
            <InputText v-if="!isAgentPlayer" type="text" @input="debounceFilters" 
            v-model="filters.transaction_id" 
            class="p-inputtext-sm" 
            :placeholder="$t('transaction_id')" />
        </template>
        </Column>
        <Column :field="isAgentPlayer ? 'transfer_type' : 'payment_type'" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div v-if="isAgentPlayer">{{  $t(data.transfer_type) }}</div>
            <div v-else>{{  $t(data.payment_type) }}</div>
        </template>
            <template #filter>
              <!-- <Dropdown  v-model="filters.payment_type" @change="applyFilters" optionLabel="text" optionValue="value" :placeholder="$t('type_transaction')" :options="transactionTypes"
            :emptyFilterMessage="$t('no_data')"> 
            </Dropdown>-->
              <v-menu v-model="typeMenu" 
              offset-y
              :close-on-content-click="false" 
              transition="scale-transition"
                  nudge-left="0" 
                  nudge-right="0"
                  
                  >
                  <template v-slot:activator="{ on }">
                   
                      <span class="p-input-icon-right" v-on="on" >
                        <i class="pi pi-angle-down" />
                        <InputText 
                        :value="currentType"
                        type="text" readonly class="p-inputtext-sm" :placeholder="$t('method')">
                        </InputText>
                      </span>
                  </template>
                    <Listbox v-model="currentType" :options="getTransactionTypes"
                    @change="applyFilters"
                    :emptyFilterMessage="$t('no_data')"
                    optionLabel="text" 
                    optionValue="value"
                    
                    style="width:15rem" />
      
                </v-menu>
          </template>
        </Column>
        <Column :field="isAgentPlayer ? 'log_type' : 'payment_sub_type'" :header="isAgentPlayer ? $t('log_type') : $t('payment_type')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div v-if="isAgentPlayer">{{ $t(data.log_type) }}</div>
            <div v-else>{{ $t(data.payment_sub_type) }}</div>
        </template>
        <template #filter>
      </template>
        </Column>
        <Column field="amount" :header="$t('amount')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <div class="d-flex align-center">
              <span>{{ slotProps.data.amount }} </span>
              <span class="ml-1">{{ slotProps.data.currency }}</span>
            </div>
        </template>
        <template #filter>
          <v-menu
          v-if="!isAgentPlayer"
                v-model="balanceMenu"
                :close-on-content-click="false"
                offset-y
                nudge-left="0" 
                nudge-right="0" 
              >
                <template v-slot:activator="{ on }">
                <span v-on="on" class="p-float-label p-input-icon-right" >
                  <i class="pi pi-money-bill" />
                  <InputText
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="getBalancePlaceholder"
                  
                  >
                </InputText>
              </span>
                </template>
                <div class="input-menu__menu">
                  <InputText type="text" 
                  v-model="filters.min_amount" 
                  @input="debounceFilters" 
                  class="p-inputtext-sm" :placeholder="$t('amount_from')" />
                  <InputText type="text" v-model="filters.max_amount" 
                  @input="debounceFilters" 
                  class="p-inputtext-sm" :placeholder="$t('amount_to')" />
                </div>
                
              </v-menu>
        </template>
        </Column>
        <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <div style="white-space: nowrap">
              {{ new Date(slotProps.data.created_at).toLocaleString() }}
            </div>
        </template>
        <template #filter>
          <v-menu
          v-if="!isAgentPlayer"
          v-model="dateMenu"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on }">
            
            <InputText
            v-on="on"
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="$t('date')"
                  v-model="getFilteredDates"
                  dateFormat="mm.dd.yy"
                  >
                </InputText>
          
          </template>
          <Calendar class="calendar-menu"  :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
        </v-menu>
        </template>
        </Column>
        <Column field="method" v-if="!isAgentPlayer" :header="$t('method')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #filter >
            <!-- <Dropdown v-model="filters.provider" @change="applyFilters" :placeholder="$t('method')" :options="providers"
                    :emptyFilterMessage="$t('no_data')">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                          <span>{{`${slotProps.value.codename} (${slotProps.value.type})`}}</span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-car-option">
                    <span>{{`${slotProps.option.codename} (${slotProps.option.type})`}}</span>
                  </div>
                </template>
                  
                  </Dropdown> -->
                  <!-- <v-menu
                  v-model="methodMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  nudge-left="0" 
                  nudge-right="0" 
                >
                  <template v-slot:activator="{ on }">
                    <InputText
                    v-on="on"
                    type="text"
                    readonly
                    class="p-inputtext-sm"
                    v-model="filters.provider"
                    :placeholder="$t('method')"
                    >
                  </InputText>
                  </template>
                  <div class="input-menu__menu input-menu__menu_listbox">
                    <Listbox v-model="filters.provider" @change="methodMenu = false" :options="providers" optionValue="id" optionLabel="id" style="width:15rem" >
                      <template #option="slotProps">
                        <div>
                            <div>{{`${slotProps.option.codename} (${slotProps.option.type})`}}</div>
                        </div>
                    </template>
                      </Listbox>
                  </div>
                  
                </v-menu> -->
          </template>
        </Column>
        <Column field="status" v-if="!isAgentPlayer" :header="$t('status')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <v-chip small label
            :color="statusLabelColor(data.status)"
            style="color: var(--surface-a)"
            >
              {{ statusText(data.status) }}
            </v-chip>
        </template>
        <template #filter >
          <!-- <Dropdown  v-model="filters.status" @change="applyFilters" optionLabel="text" optionValue="value" :placeholder="$t('type_transaction')" :options="statusOptions"
                    :emptyFilterMessage="$t('no_data')">
                    
                  </Dropdown> -->
                  <v-menu v-model="statusMenu" 
                  v-if="!isAgentPlayer"
                  offset-y
                  :close-on-content-click="false" 
                      nudge-left="0" 
                      nudge-right="0"
                      
                      >
                      <template v-slot:activator="{ on }">
                       
                          <span class="p-input-icon-right" v-on="on" >
                            <i class="pi pi-angle-down" />
                            <InputText 
                            :value="filters && filters.status ? statusText(filters.status) : ''"
                            type="text" readonly class="p-inputtext-sm" :placeholder="$t('status')">
                            </InputText>
                          </span>
                      </template>
                        <Listbox v-model="filters.status" :options="statusOptions"
                        @change="applyFilters"
                        :emptyFilterMessage="$t('no_data')"
                        optionLabel="text" 
                        optionValue="value"
                        
                        style="width:15rem" >
                        <template #option="slotProps">
                          <v-chip small label
                :color="statusLabelColor(slotProps.option.value)"
                style="color: var(--surface-a)"
                >
                  {{ statusText(slotProps.option.text) }}
                </v-chip>
                      </template>
                        </Listbox>
          
                    </v-menu>
        </template>
        </Column>
      </DataTable>
      <games-paggination 
                        :pagginationObject="filters" 
                        :requestData="payments" 
                        @apply-filters="applyFilters" 
                        :isStatic="true"
                        />
    </div>
</template>


<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex';
export default {
  name: "PaymentsTab",
  props: {
    playerId: {
      required: true,
      default: null,
    },
    isAgentPlayer: {
      type: Boolean,
    }
  },
  watch:{
    datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.applyFilters()
            }
          },
  },
  data() {
    return {
      statusMenu: false,
      typeMenu: false,
      loadingFlag: false,
      methodMenu: false,
      balanceMenu: false,
      currentLocale: this.$i18n.locale || 'en',
      dateMenu: false,
      today: new Date(),
      moreMenu: false,
      datesToFilter: [],
      transactionTypes: [{value: 'deposit', text: this.$i18n.t('deposit')}, {
        value: 'withdraw',
        text: this.$i18n.t('withdraw')
      }],
      statusOptions: [
        {value: 0, text: this.$t('processing')},
        {value: 1, text: this.$t('completed')},
        {value: 2, text: this.$t('processed')},
        {value: 3, text: this.$t('canceled')}
      ],

      filters: {
        transaction_id: '',
        min_amount: '',
        max_amount: '',
        payment_type: '',
        date_from: '',
        date_to: '',
        provider: '',
        status: '',
      },
      startPage: 1,
      startSize: 25,
      activeFilters: {},
    }
  },
  computed: {
    ...mapGetters({
          payments: 'getPlayerPayments',
          agentPlayer: 'agents/getPlayerLogs'
        },
    ),
    getTransactionTypes() {
      if (this.isAgentPlayer) {
        return [{value: 'deposit', text: this.$i18n.t('deposit')}, {
        value: 'withdraw',
        text: this.$i18n.t('withdraw')
      },
      {
        value: 'deposit_from_jackpot',
        text: this.$i18n.t('deposit_from_jackpot')
      }
    ];
      } else{
        [{value: 'deposit', text: this.$i18n.t('deposit')}, {
        value: 'withdraw',
        text: this.$i18n.t('withdraw')
      }];
      }
    },
    getCurrentLogs() {
      return this.isAgentPlayer ? this.agentPlayer : this.payments
    },
    currentType: {
      get() {
        return this.isAgentPlayer ? this.filters.transfer_type : this.filters.payment_type;
      },
      set(value) {
        if (this.isAgentPlayer) {
          this.filters.transfer_type = value;
        } else {
          this.filters.payment_type = value;
        }
      }
    },
    getBalancePlaceholder() {
      const { min_amount, max_amount } = this.filters;
      const trimMinBalance = min_amount.trim();
      const trimMaxBalance = max_amount.trim();
        if (!trimMinBalance && !trimMaxBalance) {
          return this.$t('amount');
        } else if (!trimMinBalance) {
          return this.$t('to') + ' ' + trimMaxBalance;
        } else if (!trimMaxBalance) {
          return this.$t('from') + ' ' + trimMinBalance;
        } else if(trimMinBalance && trimMaxBalance) {
          return `${this.$t('from')} ${trimMinBalance} ${this.$t('to')} ${trimMaxBalance}`
        } else {
          return this.$t('amount');
        }
    },
    getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
    filteredActiveFilters() {
      return Object.fromEntries(
        Object.entries(this.activeFilters).filter(
          ([key, value]) => key !== 'size' && key !== 'page' && key !== 'user_id'
        )
      );
    },
  },
  async mounted() {
    await this.applyFilters()
  },
  methods: {
    debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    async getTransactionItem(transaction) {
      if (this.$route.path !== `/payments/${transaction.transaction_id}`) {
        this.$router.push({ 
         name: 'PlayerTransaction', 
         params: { 
          playerId: this.playerId,
          paymentId: transaction.transaction_id,
         },
        });
      }
    },
    getFilterValueTitle(key) {
      // key
      switch (key) {
        case 'transaction_id':
          return this.$t('transaction_id')
        case 'status':
          return this.$t('status')
        case 'min_amount':
          return this.$t('amount_from')
        case 'max_amount':
          return this.$t('amount_to')
        case 'user_id':
          return 'id'
        case 'provider':
          return this.$t('method')
        case 'payment_type':
          return this.$t('type_transaction')
        case 'deposit_from_jackpot':
          return this.$t('deposit_from_jackpot')
        case 'transfer_type':
          return this.$t('type_transaction')
        case 'date_from':
          return this.$t('date_from')
        case 'date_to':
          return this.$t('date_to')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // логика для текста фильтров
      
      switch (value) {
        
        case 0:
          return this.$t('processing')
        case 1:
          return this.$i18n.t('completed');
        case 2:
          return this.$i18n.t('processed');
        case 3:
          return  this.$i18n.t('canceled');
        case 'deposit':
          return this.$i18n.t('deposit');
        case 'withdraw':
          return  this.$i18n.t('withdraw');
        case 'jackpot':
          return  this.$i18n.t('jackpot');
      }

      return value;
    },
    statusText(status) {
      switch (status) {
        case 0:
          return this.$i18n.t('processing');
        case 1:
          return this.$i18n.t('completed');
        case 2:
          return this.$i18n.t('completed_with_fee');
        case 3:
          return  this.$i18n.t('canceled');
          default: 
          return status
      }
    },
    statusLabelColor(status) {
      switch (status) {
        case 0:
          return 'var(--yellow-500)';
        case 1:
          return 'var(--green-600)';
        case 2:
          return 'var(--yellow-500)';
        case 3:
          return  'var(--red-500)';
          default: 
          return status
      }
    },
    async setAndSubmitFilters() {
      this.loadingFlag = true;
      let method
      if (this.isAgentPlayer) {
        method = 'agents/awaitGetAgentPlayerPayments';
      } else{
        method = 'playerPayments'
      }
      try {
        await this.$store.dispatch(`${method}`, this.filters);
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
      } finally{
        
        this.loadingFlag = false;
      }
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key, value);
        }
      } 
    },
    async applyFilters() {
      this.filters.user_id = this.playerId;
      this.typeMenu = false;
      this.statusMenu = false;
      this.getFormattedDate();
      this.filters.page = this.startPage;
      this.filters.size = this.startSize;
      this.$store.commit('paggination/SET_LOADING_FLAG', true);
      this.$store.commit('paggination/SET_FILTERS', this.filters);
      this.setAndSubmitFilters();
    },
    getFormattedDate() {
      if (this.datesToFilter.length > 0) {
        const formattedDates = this.datesToFilter.map(dateStr => {
          const date = new Date(dateStr);
          return date.toISOString().split('T')[0];
        });
        const formattedDateOne = formattedDates[0];
        const formattedDateTwo = formattedDates[1];
      if (this.datesToFilter.length === 2) {
        // both data
        this.filters.date_from = formattedDateOne;
        this.filters.date_to = formattedDateTwo;
      } else if (this.datesToFilter.length === 1) {
        // only one
        this.filters.date_from = formattedDateOne;
      } 
    }
    },
    async clearFilters() {
      this.loadingFlag = true;
      Object.keys(this.filters).forEach((key, index) => {
        this.filters[key] = ''
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      this.datesToFilter = []
      this.filters.page = this.startPage;
      this.filters.size = this.startSize;
      this.filters.user_id = this.playerId;
      this.$store.commit('paggination/SET_FILTERS', this.filters);
      this.setAndSubmitFilters();
    },
    async removeFilter(key) {
      this.loadingFlag = true;
      // Delete active filters
      this.$delete(this.activeFilters, key);
      // Submit empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      if (key === 'date_from') {
        this.datesToFilter.splice(0, 1);
      }
      if (key === 'date_to') {
        this.datesToFilter.splice(1, 1);
      }
      this.$store.commit('paggination/SET_FILTERS', this.filters);
      this.setAndSubmitFilters();
    },
  }
}
</script>

<style lang="scss" scoped>
.input-menu {
  position: relative;

  &__menu {
    display: flex;
    flex-direction: column;
    background-color: var(--surface-a);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;

    .p-component {
      &:not(:last-child) {

        margin-bottom: 5px;
      }
    }
    &_listbox{
      padding: 0;
      background-color: transparent;
    }
  }
}
</style>
