<template>
  <div>
    <Card v-on:keyup.enter="applyFilters" class="br-0">
      <!-- <template #title>
        <div class="d-flex justify-center">
          <h4>{{ $i18n.t('search') }}</h4>
          <span class="column-three p-buttonset justify-end d-flex">
            <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
            <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')" icon="pi pi-search" class="p-button-sm p-button-success" />
        </span>
        </div>
      </template> -->
      <template #content>  
            <Button @click="toAddCommentModal" 
            :disabled="loadingFlag || !allowedAddComment" 
            :loading="loadingFlag"
            v-tooltip.right="!allowedAddComment ? $t('no_permission') : $t('tooltip_add_comment')"  
            :label="$t('addNewCom')" icon="pi pi-plus" class="p-button-sm p-button-success d-flex justify-start" />
            <v-chip-group v-if="filteredActiveFilters">
              <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key">
                <template>
                {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                </template>
                <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
              </v-chip>
            </v-chip-group>
      </template>
      </Card>
      <DataTable 
      :value="comments" 
      :rows="comments.length"
      :loading="loadingFlag" 
      filterDisplay="row"
      selectionMode="single"
      :scrollable="true"
      @row-select="showTagInfo"
      :paginator="true"
        class="p-datatable-sm"
        >
        <template #empty>
         <div class="font-weight-bold">{{ $i18n.t('comments_not_found') }} </div>
      </template>
        <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'max-width':'25rem'}">
          <template #body="{data}">
            <div style="white-space: nowrap">
              {{ new Date(data.created_at).toLocaleString() }}
            </div>
        </template>
        <template #filter>
          <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            
            <InputText
            v-on="on"
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="$t('date')"
                  v-model="getFilteredDates"
                  dateFormat="mm.dd.yy"
                  >
                </InputText>
          
          </template>
          <Calendar class="calendar-menu"  
          @date-select="applyFilters"
          :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="multiple" />
        </v-menu>
        </template>
        </Column>
        <Column field="admin" :header="$t('itWas')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div style="white-space: nowrap">
              {{ data.admin }}
            </div>
        </template>
        <template #filter>
        </template>
        </Column>
        <Column field="text" :header="$t('isCom')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div>
              {{ data.text }}
            </div>
        </template>
        <template #filter>
        </template>
        </Column>
      </DataTable>
    <v-dialog v-model="addCommentModal" content-class="br-16" max-width="500">
      <v-card>
        <v-card-title class="pb-0 justify-center"> 
          <h3>{{ $i18n.t('addCom') }}</h3>
          <close-modal-button 
          @close-modal="addCommentModal = false"
          />
  </v-card-title>
        <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
          <v-card-text class="pb-0 pt-1 text-left space-y-2">
                          <ValidationProvider
                  :name="$t('comment')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('textCom') }}<span class="c-red">*</span>:</h4>
                  <div class="d-flex flex-column">
                    <!-- :minFractionDigits="2" -->
                    <Textarea v-model="newTag" 
                  :autoResize="true" rows="5" class="fw" />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <Button @click="handleSubmit(validate)" :loading="formLoading" 
            :disabled="formLoading" 

              :label="$t('add')" icon="pi pi-save" class="p-button-sm p-button-success fw" />
          </v-card-actions>
      </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog v-model="commentInfoModal" scrollable content-class="br-16" max-width="600">
      <v-card>
        <v-card-title class="justify-center">
            <h3>{{$t('isCom')}}</h3>
          </v-card-title>
          <v-card-text>
            
          <v-row align="center" class="c-text font-weight-bold">
            <v-col class="item">
              {{ $t('addBy') }}: 
              <v-chip small label color="var(--red)">
                <span class="c-white">{{ showingComment.admin }}</span>
              </v-chip>
            </v-col>
            <v-col class="d-flex item lh-1-5 justify-sm-center">
              <span class="mr-1">{{ $t('date') }}: </span>
              <span>{{new Date(showingComment.created_at).toLocaleString()}}</span>
            </v-col>
          </v-row>
          <div class="pt-2 font-weight-bold c-text">
            <Textarea v-model="showingComment.text" 
            readonly :autoResize="true" rows="5" class="fw" />
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "CommentsTab",
    props: {
      playerId: {
        required: true,
        default: null,
      }
    },
    watch:{
      datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.dateMenu = false;
              this.applyFilters()
            }
          },
    },
    computed: {
      ...mapGetters({
        permissions: 'roles/elementsPlayerPermission',
      }),
            allowedAddComment() {
              if (this.permissions) {
                if (this.permissions.includes('add_comment') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
      filteredActiveFilters() {
        const filtersLength = Object.keys(this.activeFilters).length;
        // console.log("filtersLength", filtersLength);
        if (filtersLength >= 2) {
    return Object.fromEntries(
      Object.entries(this.activeFilters).filter(
        ([key, value]) => key !== 'size' && key !== 'page' && key !== 'user_id'
      )
    );
  } else{
    return null
  }
        },
        getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            console.log('this.datesToFilter[1]', this.datesToFilter[1]);
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
    },
    data(){
      return{
        loadingFlag: false,
        formLoading: false,
        addCommentModal: false,
        minDate: '',
        maxDate: '',
        newTag: '',
        commentInfoModal: false,
        today: new Date(),
        showingComment: '',
        comments: [],
        dateMenu: false,
        datesToFilter: [],
        activeFilters: {},
        currentLocale: this.$i18n.locale || 'en',
        filters:{
          min_date: '',
          max_date: '',
          user_id: '',
        },
      }
    },
    async mounted() {
      await this.applyFilters()
    },
    methods:{
      toAddCommentModal(){
        this.addCommentModal = true
        this.$nextTick().then(() => {
          this.$refs.observer.reset();
        })
      },
      handleSubmit(validate) {
        validate().then(valid => {
          if (valid) {
            this.addTag();
          }
        })
      },
      removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$delete(this.activeFilters, key);
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      if (key === 'min_date') {
        this.datesToFilter.splice(0, 1);
      }
      if (key === 'max_date') {
        this.datesToFilter.splice(1, 1); // $delete(this.datesToFilter[1]);
      }
      this.setAndSubmitFilters();
    },
    async setAndSubmitFilters(){
      this.loadingFlag = true;
      this.comments = await this.$store.dispatch('getPlayerTags', this.filters);
        for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key);
        }
      }
      this.loadingFlag = false;
    },
      async applyFilters(){
        this.loadingFlag = true;
        this.filters.user_id = this.playerId;
        this.getFormattedDate();
        this.setAndSubmitFilters();
      },
      getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.min_date = formattedDateOne;
            this.filters.max_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.min_date = formattedDateOne;
          }
        }
    },
      async clearFilters() {
      Object.keys(this.filters).forEach((key, index) => {
        this.filters[key] = ''
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      this.datesToFilter =[],
      this.filters.user_id = this.playerId;
      await this.$store.dispatch('getPlayerTags', this.filters);
    },
      async addTag(){
        this.formLoading = true;
        try {
            await this.$store.dispatch('createPlayerTag', {
              text: this.newTag,
              id: this.playerId});
            await this.applyFilters();
            this.newTag = '';
            this.addCommentModal = false;
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('just_error'),
              life: 4000})
          } finally{
            this.formLoading = false;
          }
      },
      showTagInfo(tag) {
        const info = tag.data;
        this.showingComment = tag.data;
        this.commentInfoModal = true;
      },
      getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'is_active':
          return this.$t('status')
        case 'min_date':
          return this.$t('date_from')
        case 'max_date':
          return this.$t('date_to')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values

      return value;
    },
    }
  }
</script>

<style lang="scss" scoped>
.item{
  font-size: 14px;
  flex: 0 1 50%;
  @media (max-width:768px){
      flex: 1 1 100%;
  }
}
.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
</style>
