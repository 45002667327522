<template>
    <div>
      <v-chip-group>
        <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
          <template>
          {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
          </template>
          <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
        </v-chip>
      </v-chip-group>
      <DataTable 
      :value="authSessions.items" 
      :loading="loadingFlag" 
      filterDisplay="row"
        class="p-datatable-sm"
        >
        <template #empty>
         <div class="font-weight-bold">{{ $i18n.t('auth_player_empty') }} </div>
      </template>
        <Column field="ip" header="IP" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            
              <div>{{ data.ip }}</div>
          </template>
          <template #filter>
            <InputText type="text" @input="debounceFilters" 
            v-model="filters.ip" 
            class="p-inputtext-sm" 
            placeholder="IP" />
        </template>
        </Column>
        <Column field="timezone" :header="$t('timezone')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            
              <div>{{ data.timezone }}</div>
          </template>
          <template #filter>
            <!-- <InputText type="text" 
            disabled
            @input="debounceFilters" 
            v-model="filters.ip" 
            class="p-inputtext-sm no-active" 
            :placeholder="$t('timezone')" /> -->
        </template>
        </Column>
        <Column field="browser" :header="$t('user_agent')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            
              <div>{{ data.browser }}</div>
          </template>
          <template #filter>
            <!-- <InputText type="text" 
            disabled
            @input="debounceFilters" 
            v-model="filters.browser" 
            class="p-inputtext-sm no-active" 
            :placeholder="$t('user_agent')" /> -->
        </template>
        </Column>
        <Column field="date" :header="$t('date')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            
            <div>{{ new Date(data.date).toLocaleString() }}</div>
            </template>
            <template #filter>
              <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                
                <InputText
                v-on="on"
                      type="text"
                      readonly
                      class="p-inputtext-sm"
                      :placeholder="$t('date')"
                      v-model="getFilteredDates"
                      dateFormat="mm.dd.yy"
                      >
                    </InputText>
              
              </template>
              <Calendar class="calendar-menu"  :placeholder="$t('date')" :inline="true" 
              :maxDate="today" id="icon" 
              dateFormat="mm.dd.yy" 
              v-model="datesToFilter" 
               selectionMode="range"       
               />
            </v-menu>
            </template>
            </Column>
              <Column field="hash" :header="$t('fingerprint_hash')" :showFilterMenu="false">
                <template #body="{data}">
                  
                    <div>{{ data.hash }}</div>
                </template>
                <template #filter>
                  <InputText type="text" 
                  @input="debounceFilters" 
                  v-model="filters.hash" 
                  class="p-inputtext-sm" 
                  :placeholder="$t('fingerprint_hash')" />
              </template>
              </Column>
      </DataTable>
      <games-paggination 
                        :pagginationObject="filters" 
                        :requestData="authSessions" 
                        @apply-filters="applyFilters" 
                        :isStatic="true"
                        />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {debounce} from 'lodash'
export default {
  name: "AuthTab",
  props: {
    playerId: {
      required: true,
      default: null,
    }
  },
  computed: {
    getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
    filteredActiveFilters() {
      return Object.fromEntries(
        Object.entries(this.activeFilters).filter(
          ([key, value]) => key !== 'size' && key !== 'page' && key !== 'user_id'
        )
      );
    },
  },
  watch:{
    datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.dateMenu = false;
              this.applyFilters();
            }
          },
  },
  data() {
    return {
      loadingFlag: false,
      today: new Date(),
      datesToFilter:[],
      activeFilters: {},
      dateMenu: false,
      authSessions: [],
      filters:{
        page: 1,
        size: 25,
        user_id: '',
      },
    }
  },
  async mounted() {
    this.applyFilters();
    },
    methods:{
      debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$delete(this.activeFilters, key);
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      if (key === 'start_date') {
        this.datesToFilter.splice(0, 1);
      }
      if (key === 'end_date') {
        this.datesToFilter.splice(1, 1); // $delete(this.datesToFilter[1]);
        
      }
      this.setAndSubmitFilters();
    },
      applyFilters() {
        this.filters.user_id = this.playerId;
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.getFormattedDate();
        this.setAndSubmitFilters();
      },
      getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.start_date = formattedDateOne;
            this.filters.end_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.start_date = formattedDateOne;
          }
        }
    },
      async setAndSubmitFilters() {
        this.loadingFlag = true;
        try {
      this.authSessions = await this.$store.dispatch('getPlayerAuthSessions',
          this.filters);
    } catch (error) {
      console.log(error);
      this.$toast.add({severity:'error', summary: this.$t('just_error'), detail: error.message, life: 3000})
    } finally{
      this.loadingFlag = false;
      this.setActiveFilters();
    }
      
      },
      setActiveFilters() {
        for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key, value);
        }
      } 
      },
      getFilterValueTitle(key) {
      // key
      switch (key) {
        case 'ip':
          return 'IP'
        case 'start_date':
          return this.$t('date_from')
        case 'end_date':
          return this.$t('date_to')
        case 'hash':
          return this.$t('fingerprint_hash')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // text
      
      // switch (value) {

      // }

      return value;
    },
    },
}
</script>

<style scoped>

</style>
