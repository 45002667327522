<template>
    <div>
      <template v-if="!showSelectedSession">
        <Card class="br-0">
              <template #content>  
                <v-chip-group>
                  <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
                    <template>
                    {{ $t(key) }}: {{ getFilterValueText(key,value) }}
                    </template>
                    <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                  </v-chip>
                </v-chip-group>
          </template>
          </Card>
          <DataTable :value="sessions.items" 
        v-if="!showSelectedSession"
        selectionMode="single"
        :rows="sessions.size"
        @row-select="holdSession"
        :loading="loadingFlag" 
        filterDisplay="row"
          class="p-datatable-sm"
          :class="{'table-padding' : sessions && sessions.length < 5 }"
          >
          <template #empty>
           <div class="font-weight-bold text-center">{{ $i18n.t('sessions_not_found') }} </div>
        </template>
          <Column field="_id" header="ID" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="slotProps">
              
              <button type="button" class="pa-0 c-main-flamingo" @click="holdSession(slotProps)">
                {{ slotProps.data._id }}
              </button>
            </template>
            <template #filter>
              <InputText type="text" @input="debounceFilters" v-model="filters.id" class="p-inputtext-sm" placeholder="Id" />
          </template>
          </Column>
          <Column field="aggregator" :header="$t('aggregator')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
                <span class="text-capitalize">{{data.aggregator}}</span>
          </template>
              <template #filter>
                <InputText type="text" @input="debounceFilters" v-model="filters.aggregator" class="p-inputtext-sm" :placeholder="$t('aggregator')" />
            </template>
          </Column>
          <Column field="provider" :header="$t('provider')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <span class="custom-badge _main"
                >
                  <span>{{ getProviderName(data.provider) }} </span>
                </span>
          </template>
              <template #filter>
                <InputText type="text" @input="debounceFilters" v-model="filters.provider" class="p-inputtext-sm" :placeholder="$t('provider')" />
            </template>
          </Column>
          <Column field="game_name" :header="$t('game_name')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div>{{ data.game_name }}</div>
          </template>
          <template #filter>
            <!-- <Dropdown v-model="filters.game" @change="applyFilters" optionValue="" :options="filteredGames"
                    :placeholder="$t('game')" :filter="true" :emptyFilterMessage="$t('gameNotFound')">
                  </Dropdown> -->
                  <InputText type="text" 
                  @input="debounceFilters" 
                  
                  v-model="filters.game" class="p-inputtext-sm" :placeholder="$t('game')" />
        </template>
          </Column>
          
          <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div style="white-space: nowrap" v-if='data.created_at'>
                {{ new Date(data.created_at).toLocaleString() }}
              </div>
              <div v-else>
                {{ $t('no_date') }}
              </div>
          </template>
          <template #filter>
            <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              
              <InputText
              v-on="on"
                    type="text"
                    readonly
                    class="p-inputtext-sm"
                    :placeholder="$t('date')"
                    v-model="getFilteredDates"
                    dateFormat="mm.dd.yy"
                    >
                  </InputText>
            
            </template>
            <Calendar class="calendar-menu"  :placeholder="$t('date')" 
            :inline="true" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
          </v-menu>
          </template>
          </Column>
          <Column field="bet" :header="$t('bet_amount')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div>{{ data.bet }}</div>
          </template>
          <template #filter>
            <v-menu
                v-model="betsMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                nudge-left="0" 
                nudge-right="0" 
              >
                <template v-slot:activator="{ on }">
                <span v-on="on" class="p-float-label p-input-icon-right" >
                  <i class="pi pi-money-bill" />
                  <InputText
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="getBetPlaceholder"
                  
                  >
                </InputText>
              </span>
                </template>
                <div class="input-menu__menu">
                  <InputText type="text" 
                  v-model="filters.min_bet" 
                  @input="debounceFilters" 
                  class="p-inputtext-sm" :placeholder="$t('min_bet')" />
                  <InputText type="text" v-model="filters.max_bet" 
                  @input="debounceFilters" 
                  class="p-inputtext-sm" :placeholder="$t('max_bet')" />
                </div>
                
              </v-menu>
              </template>
              </Column>
              <Column field="result" :header="$t('result')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
                <template #body="{data}">
                  <span class="custom-badge"
                :class="getColorResult(data.result)"
                >

                  <span>{{ getResult(data.result) }} </span>
                </span>
                  <!-- <v-chip 
                  label 
                  small 
                  :color="getColorResult(data.result)"
                  >
                    <span class="c-white text-capitalize">
                      {{ getResult(data.result) }}
                    </span>
                  </v-chip> -->
                  </template>
          <template #filter>
            <v-menu
                v-model="resultMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                nudge-left="0" 
                nudge-right="0" 
              >
                <template v-slot:activator="{ on }">
                <span v-on="on" class="p-float-label p-input-icon-right" >
                  <InputText
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :value="getResult(filters.result)"
                  :placeholder="$t('result')"
                  >
                </InputText>
              </span>
                </template>
                <Listbox v-model="filters.result" :options="resultOptions"
                        @change="applyFilters"
                        optionLabel="name"
                        optionValue="value"
                        :emptyFilterMessage="$t('no_data')"
                        style="width:15rem" >
                        </Listbox>
                
              </v-menu>
        </template>
        </Column>
          <Column field="finish_balance" :header="$t('finish_balance')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div>{{data.finish_balance}}</div>
              </template>
              <template #filter>
            <v-menu
                v-model="balanceMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                nudge-left="0" 
                nudge-right="0" 
              >
                <template v-slot:activator="{ on }">
                <span v-on="on" class="p-float-label p-input-icon-right" >
                  <i class="pi pi-money-bill" />
                  <InputText
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="getBalancePlaceholder"
                  
                  >
                </InputText>
              </span>
                </template>
                <div class="input-menu__menu">
                  <InputText type="text" 
                  v-model="filters.min_finish_balance" 
                  @input="debounceFilters" 
                  class="p-inputtext-sm" :placeholder="$t('min_finish_balance')" />
                  <InputText type="text" v-model="filters.max_finish_balance" 
                  @input="debounceFilters" 
                  class="p-inputtext-sm" :placeholder="$t('max_finish_balance')" />
                </div>
                
              </v-menu>
        </template>
          </Column>
          <Column field="currency" :header="$t('currency')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
            <template #body="{data}">
              <div>{{data.currency}}</div>
          </template>
          <template #filter>
            <!-- <Dropdown v-model="filters.currency" @change="applyFilters" :options="filteredCurrencies"
                    :placeholder="$t('currency')" :filter="true" :emptyFilterMessage="$t('no_data')">
                  </Dropdown> -->
                  <v-menu v-model="currencyMenu" 
                  offset-y
                  :close-on-content-click="false" 
                  transition="scale-transition"
                      nudge-left="0" 
                      nudge-right="0"
                      
                      >
                      <template v-slot:activator="{ on }">
                       
                          <span class="p-input-icon-right" v-on="on" >
                            <i class="pi pi-angle-down" />
                            <InputText 
                            :value="filters && filters.currency ? filters.currency : ''"
                            type="text" readonly class="p-inputtext-sm" :placeholder="$t('currency')">
                            </InputText>
                          </span>
                      </template>
                        <Listbox v-model="filters.currency" :options="filteredCurrencies"
                        @change="applyFilters"
                        :emptyFilterMessage="$t('no_data')"
                        style="width:15rem" >
                        </Listbox>
          
                    </v-menu>
        </template>
          </Column>
        </DataTable>
        <games-paggination 
                        :pagginationObject="filters" 
                        :requestData="sessions" 
                        @apply-filters="setAndSubmitFilters" 
                        :isStatic="true"
                        />
      </template>
        <SelectSession 
        :loadingFlag="loadingFlag"
        :sessionInfo="sessionInfo" 
        :provider="sessionInfo.provider"
        :aggregator="sessionInfo.aggregator"
        :game="sessionInfo.game_name"
        :currency="playerCurrency"
        v-if="showSelectedSession"
        />
    </div>
  </template>
  
  <script>
  import {debounce } from 'lodash'
  import SelectSession from "./SelectSession";
  import Api from "@/components/api";
  import { mapGetters } from "vuex";
  import moment from 'moment';
  export default {
    name: "PlayerGameSessions",
    props: {
      playerId: {
        required: true,
        default: null,
      },
      playerCurrency: {
        type: String,
      }
    },
    components:{
      SelectSession
    },
    data() {
      return {
        filters: {
          size: 25,
          page: 1,
          min_bet: '',
          max_bet: '',
          min_finish_balance: '',
          max_finish_balance: '',
          aggregator: '',
          provider: '',
          game: '',
        },
        startSize: 25,
        startPage: 1,
        resultMenu: false,
        resultOptions: [
          {
          name: this.$t('win'),
          value: true
          },
          {
          name: this.$t('lose'),
          value: false
          },
        ],
        dateMenu: false,
        betsMenu: false,
        currencyMenu: false,
        today: new Date(),
        datesToFilter: [],
        currentLocale: this.$i18n.locale || 'en',
        loadingFlag: false,
        sessions: [],
        selectedSession: null,
        balanceMenu: false,
        sessionInfo: {},
        activeFilters: {},
        usedId: null,
      }
    },
    watch:{
      datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.applyFilters()
            }
          },
    },
    async mounted() {
      // if (this.$store.getters.getGames.length === 0) {
      //  await this.$store.dispatch('getGames', this.filters);
      // }
      //await this.$store.dispatch('playerProviders', {id: this.playerId});
      this.applyFilters()
    },
    computed: {
      ...mapGetters({
        game: 'getGames',
        filteredCurrencies: 'getFilteredCurrencies',
      }),
      getBetPlaceholder() {
      const { min_bet, max_bet } = this.filters;
      if (min_bet && max_bet) {
      const trimMinValue = min_bet.trim();
      const trimMaxValue = max_bet.trim();
        if (!trimMinValue && !trimMaxValue) {
          return this.$t('bet');
        } else if (!trimMinValue) {
          return this.$t('to') + ' ' + trimMaxValue;
        } else if (!trimMaxValue) {
          return this.$t('from') + ' ' + trimMinValue;
        } else if(trimMinValue && trimMaxValue) {
          return `${this.$t('from')} ${trimMinValue} ${this.$t('to')} ${trimMaxValue}`
        } else {
          return this.$t('bet');
        }
      } else{
        return this.$t('bet')
      }
    },
    getBalancePlaceholder() {
      const { min_finish_balance, max_finish_balance } = this.filters;
      if (min_finish_balance && max_finish_balance) {
      const trimMinValue = min_finish_balance.trim();
      const trimMaxValue = max_finish_balance.trim();
        if (!trimMinValue && !trimMaxValue) {
          return this.$t('finish_balance');
        } else if (!trimMinValue) {
          return this.$t('to') + ' ' + trimMaxValue;
        } else if (!trimMaxValue) {
          return this.$t('from') + ' ' + trimMinValue;
        } else if(trimMinValue && trimMaxValue) {
          return `${this.$t('from')} ${trimMinValue} ${this.$t('to')} ${trimMaxValue}`
        } else {
          return this.$t('finish_balance');
        }
      } else{
        return this.$t('finish_balance')
      }
    },
      filteredActiveFilters() {
    return Object.fromEntries(
      Object.entries(this.activeFilters).filter(
        ([key, value]) => key !== 'size' && key !== 'page' && key !== 'user_id' && key !== 'tz_offset' && value
      )
    );
        },
        showSelectedSession(){
          return this.$store.state.showSession;
        },
        getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
    },
    methods: {
      debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    getProviderName(name){
        return name.replace(/_/g, ' ');
      },
      async holdSession(session){
        if (this.usedId === session.data._id) {
          this.$store.commit('setShowSession', true);
          return
        }
        this.loadingFlag = true;
        this.sessionInfo = session.data;
        this.usedId = this.sessionInfo._id;
        this.$store.commit('setShowSession', true);
        const sessionData = {
          page: 1,
          size: 25,
          game_session_id: this.sessionInfo._id,
        }
        // console.log('sessionData', sessionData);
        try {
          await this.$store.dispatch('getGameSession', sessionData);
          
        } catch (error) {
          this.$toast.add({severity:'error', summary: this.$t('just_error'), life: 4000});
        } finally{

          this.loadingFlag = false;
        }
      },
      getFilterValueText(key, value) {
        // text active filters
        switch (value) {
          case true:
            return this.$t('yes')
          case false:
            return this.$t('no')
        }
        if (key === 'result') {
          this.getResult(value)
        }
        return value;
      },
      getResult(result){
        switch (result) {
          case 'win':
            return this.$t('win')
          case 'lose':
            return this.$t('lose')
          case 'draw':
            return this.$t('draw')
        }
      },
      getColorResult(result){
        switch (result) {
          case 'win':
            return '_green'
          case 'lose':
            return '_red'
          case 'draw':
            return '_gray'
        }
      },
      //========================================================================================================================================================
      async setAndSubmitFilters(){
        this.loadingFlag = true;
        try {
          this.sessions = await Api.getPlayerSessions(this.filters);
          
        } catch (error) {
          this.$toast.add({severity:'error', summary: this.$t('just_error'), life: 4000});
        } finally{
         
          for (const [key, value] of Object.entries(this.filters)) {
            if (value !== '') {
              this.$set(this.activeFilters, key, value);
            } else {
              this.$delete(this.activeFilters, key, value);
            }
          }
        this.loadingFlag = false;
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.$store.commit('paggination/SET_LOADING_FLAG', false);
        }
      },
      applyFilters() {
        this.dateMenu = false;
        this.currencyMenu = false;
        this.resultMenu = false;
        this.filters.user_id = this.playerId;
        this.filters.tz_offset = moment().format('Z');
        this.filters.page = this.startPage;
        this.filters.size = this.startSize;
        this.getFormattedDate();
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.setAndSubmitFilters();
      },
      getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.start_date = formattedDateOne;
            this.filters.end_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.start_date = formattedDateOne;
          }
        }
    },
      //========================================================================================================================================================
      async removeFilter(key) {
        this.loadingFlag = true;
        // Delete the key filter
        this.$delete(this.activeFilters, key);
        if (typeof this.filters[key] === 'boolean') {
          this.$set(this.filters, key, false);
        } else {
          this.$set(this.filters, key, '');
        }
        if (key === 'start_date') {
        this.datesToFilter[0] = '';
      }
      if (key === 'end_date') {
        this.datesToFilter[1] = '';
      }
        this.setAndSubmitFilters();
      },
      async clearFilters() {
        this.loadingFlag = true;
        Object.keys(this.filters).forEach((key, index) => {
          this.filters[key] = ''
        })
        Object.keys(this.activeFilters).forEach((key, index) => {
          this.$delete(this.activeFilters, key);
          })
        this.setAndSubmitFilters();
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .input-menu{
    color: rgba(0,0,0,.87);
    border: 1px solid rgba(0,0,0,.38);
    border-radius: 8px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    cursor: pointer;
    align-items: center;
    position: relative;
    &__menu{
      background-color: var(--white);
      box-shadow: none;
      padding: 10px;
      z-index: 3;
      position: relative;
      width: 100%;
      .v-input{
        margin-top: 8px;
        &:first-child{
          margin-top: 0px;
        }
      }
    }
  }
  </style>
  