import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{attrs:{"items":_vm.$store.getters.affiliateRequests,"headers":_vm.headers,"no-data-text":_vm.$i18n.t('requests_not_available'),"loading-text":this.$t('loading') + '...',"footer-props":{'items-per-page-options': [10, 25, 100, -1],'items-per-page-text': this.$i18n.t('pages')},"loading":_vm.loadingFlag},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('div',[_c(VBtn,{on:{"click":function($event){return _vm.selectAffiliate(item)}}},[_c(VIcon,[_vm._v("check")])],1)],1)]}}])}),_c(VDialog,{staticStyle:{"border-radius":"10%"},attrs:{"max-width":"fit-content"},model:{value:(_vm.affiliateOffersModalFlag),callback:function ($$v) {_vm.affiliateOffersModalFlag=$$v},expression:"affiliateOffersModalFlag"}},[_c(VCard,{staticStyle:{"min-width":"300px"}},[_c(VCardTitle,[_c('h3',[_vm._v(_vm._s(_vm.$i18n.t('affiliate_confirmation')))])]),_c(VCardText,[_c(VRow,[_c(VCol,{staticStyle:{"text-align":"center"}},[_c(VLabel,[_vm._v(" "+_vm._s(_vm.$i18n.t('select_offers'))+": ")]),_vm._l((_vm.offerOptions),function(offer,index){return _c(VCheckbox,{key:index,attrs:{"value":offer.value,"label":offer.text},model:{value:(_vm.selectedOffers),callback:function ($$v) {_vm.selectedOffers=$$v},expression:"selectedOffers"}})})],2)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"label":_vm.$i18n.t('manager'),"items":_vm.$store.getters.affManagers},model:{value:(_vm.affManager),callback:function ($$v) {_vm.affManager=$$v},expression:"affManager"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticStyle:{"margin-left":"auto","text-transform":"none"},attrs:{"size":"lg"},on:{"click":_vm.activateAffiliate}},[_vm._v(" "+_vm._s(_vm.$i18n.t('confirm'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }