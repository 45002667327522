<template>
    <div>
        <v-card class="card_style" v-if="true" style="">
            <v-card-text class="card_body_style">

                <v-row>


                    <v-col cols="12" class="label_style_center" style="text-align: center">
                      {{ $i18n.t('date') }}
                    </v-col>

                </v-row>
                <v-row>


                    <v-col >
                        <b-input-group size="md">
                            <template v-slot:append>
                                <b-icon-calendar class="h1"></b-icon-calendar>
                            </template>
                            <template v-slot:prepend>
                                <div style="padding: 8px">
                                    С
                                </div>
                            </template>
                            <v-text-field type="date" v-model="filters.minDate"></v-text-field>
                        </b-input-group>
                    </v-col>
                    <v-col >
                        <b-input-group size="md">
                            <template v-slot:prepend>
                                <div style="padding: 8px">
                                    по
                                </div>
                            </template>
                            <template v-slot:append>
                                <b-icon-calendar class="h1"></b-icon-calendar>
                            </template>
                            <v-text-field type="date" v-model="filters.maxDate" ></v-text-field>
                        </b-input-group>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-footer style="text-align: right; padding-bottom: 0px">
                <v-row style="align-content: space-between">
                    <v-col>
                        <v-btn size="lg" @click="clearFilters">{{ $i18n.t('clear') }}</v-btn>
                    </v-col>
                    <v-col cols="2">
                        <v-btn size="lg" @click="applyFilters">{{ $i18n.t('apply') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-footer>
        </v-card>
        <b-table
                striped
                hover
                :items="$store.getters.getPlayers"
                :fields="fields"
                id="players_table_id"
                style="margin-top: 10px"
                head-variant="dark"
                sticky-header="58vh"
                :busy="loadingFlag"
                show-empty
        >


            <template v-slot:empty="scope">
                <h4>{{ $i18n.t('players_not_found') }}</h4>
            </template>
        </b-table>

    </div>
</template>

<script>
    export default {
        name: "WithdrawTab",

        data(){
            return{
                /*
                * сумма депозитов, сумма выводов, сумма ставок, доход*/
                fields: [
                    { key: 'reg_date', label:  this.$i18n.t('date'), sortable: false },
                    { key: 'balance', label:  this.$i18n.t('sum'), sortable: false },
                ],
                typeOptions:[{value: 0, text:  this.$i18n.t('win')},{value: 1, text:  this.$i18n.t('loss')}],
                providerOptions:[],
                filters:{
                    type: '',
                    provider: '',
                    minDate:'',
                    maxDate:'',
                },
                loadingFlag: false,

            }
        },
        /*
        id - айди юзера
provider - название провайдера игры (список заранее задан для каждого юзера, сейчас делаю запрос)
type - значения возможнве: 0 - означает спины выигрышные, 1 - проигрышные спины (со знаком минуса идут)
minDate - дата от
maxDate - дата до
        * */
        async mounted(){

        },
        methods:{
            async applyFilters(){

            },
            async clearFilters(){

                //await this.$store.dispatch('playersFilters', {filters: this.filters});
            }
        }
    }
</script>

<style scoped>

</style>