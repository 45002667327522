<template>
  <v-main>
        <!-- <h2 class="d-flex align-center justify-center pa-3 pb-0" style="font-size: 24px; font-weight: bold; color: var(--main-flamingo);">
          <div class="mr-1">{{$i18n.t('affiliates')}}</div>
        </h2> -->
        <v-btn  class="success-btn mt-5" href="https://admin.takemybet.pro/" target="_blank">
          {{$t('open_affiliate_admin') }}
        </v-btn>
    <!-- <v-tabs
        grow
        v-model="selectedTab"
        class="main-tabs"
    >
      <v-tab>
        {{$i18n.t('search')}}
      </v-tab>
      <v-tab>
        {{$i18n.t('offers')}}
      </v-tab>
      <v-tab>
        {{$i18n.t('requests')}}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item :transition="false" :reverse-transition="false">
        <AffiliatesTab></AffiliatesTab>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <OffersTab></OffersTab>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <OffersTab></OffersTab>
      </v-tab-item>
    </v-tabs-items>
    <AffiliatesTable v-if="selectedTab === 0"></AffiliatesTable>
    <OffersTable v-if="selectedTab === 1"></OffersTable>
    <RequestsTabContent v-if="selectedTab === 2"></RequestsTabContent>
    <ScrollTopButton></ScrollTopButton> -->
  </v-main>
</template>

<script>
  // @ is an alias to /src
  //import HelloWorld from '@/components/HelloWorld.vue'
  //{ Player: 'Player 1', Affiliate: '24142xdf', Registration: '20.02.2012', Balance: '201232 RUB', Status:'Не активен'},
  import AffiliatesTab from "../components/Affiliates/AffiliatesTab";
  import OffersTab from "../components/Affiliates/OffersTab";
  import AffiliatesTable from "../components/Affiliates/AffiliatesTable";
  import OffersTable from "../components/Affiliates/OffersTable";
  import RequestsTabContent from "../components/Affiliates/RequestsTabContent";
  import ScrollTopButton from "../components/ScrollTopButton";
  export default {
    name: 'Affiliates',
    components:{
      //ScrollTopButton,
      //AffiliatesTab,
      //AffiliatesTable,
      //OffersTab,
      //OffersTable,
      //RequestsTabContent

    },
    data(){
      return{
        selectedTab: null,
      }
    },
    mounted(){
      this.$store.commit('setPageName', 'affiliates')
    },
  }
</script>
<style lang="scss">
  .card_style{
    margin-top: 10px;
    box-shadow: 0 0 5px #2c3e50;
    //border-radius: 20px!important;

  }
  .card_header_style{
    text-align: left;
    font-size: 26px;
    font-weight: 400;
    padding: 10px;
  }
  .card_body_style{
    background-color: #0f0f0f0f;

  }
  .label_style{
    text-align: left;
    font-weight: 100;
    font-size: 20px;
  }
  .label_style_center{
    font-weight: 100;
    font-size: 20px;
  }
  .modal_th{
    font-size: 20px;
  }
  .tabs_active{
    background-color: #2c3e50;
  }

  .modal-xl {
    width: 90% !important;
    //max-width: max-content!important;
    font-family: Gilroy;
    .modal-header{

      padding-bottom: 0px;
    }
    .modal-title{
      width: 80%
    }
    .modal-body{
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .modal-footer{
      border-top: none;
      padding-top: 0px;
    }
  }

  #players_table_id{
    tbody{
      font-size: 18px;
      cursor: pointer;
    }
    th{
      font-size: 20px;
    }
  }

</style>
