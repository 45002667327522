import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{staticStyle:{"padding":"10px"}},[_c(VCol,{staticStyle:{"text-align":"left"}},[_c(VBtn,{staticClass:"bg-c-red c-white",staticStyle:{"text-transform":"none"},attrs:{"size":"lg"},on:{"click":function($event){_vm.offerModalFlag = true}}},[_c(VIcon,[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$i18n.t('add_new'))+" ")],1)],1)],1),_c(VDataTable,{attrs:{"items":_vm.$store.getters.affiliateOffers,"headers":_vm.fields,"fixed-header":"","no-data-text":_vm.$i18n.t('requests_not_available'),"loading-text":this.$t('loading') + '...',"loading":_vm.loadingFlag}}),_c(VDialog,{attrs:{"persistent":"","content-class":"br-16","max-width":"900"},model:{value:(_vm.offerModalFlag),callback:function ($$v) {_vm.offerModalFlag=$$v},expression:"offerModalFlag"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-1"},[_c('h5',[_vm._v(_vm._s(_vm.$i18n.t('add_offer')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.offerModalFlag = false}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VCardText,[_c('div',[_c(VRow,[_c(VCol,{staticClass:"col",attrs:{"xl":"3","md":"3","sm":"6","cols":"12"}},[_c(VSelect,{attrs:{"hide-details":"","outlined":"","dense":"","color":"var(--main-flamingo)","items":[
                      { value: 'REVENUE_SHARE', text: _vm.$i18n.t('revenue_share') },
                      { value: 'CPA', text: _vm.$i18n.t('cost_per_acquisition') },
                      { value: 'TIERED_REVENUE_SHARE', text: _vm.$i18n.t('tiered_revenue_share') }
                    ],"label":_vm.$i18n.t('type')},model:{value:(_vm.newOfferType),callback:function ($$v) {_vm.newOfferType=$$v},expression:"newOfferType"}})],1),_c(VCol,{staticClass:"col",attrs:{"xl":"3","md":"3","sm":"6","cols":"12"}},[_c(VTextField,{attrs:{"hide-details":"","outlined":"","dense":"","color":"var(--main-flamingo)","label":_vm.$i18n.t('rate'),"type":"number","step":"1","min":"10","max":"90","state":_vm.amountValid},model:{value:(_vm.newOfferFee),callback:function ($$v) {_vm.newOfferFee=_vm._n($$v)},expression:"newOfferFee"}})],1),_c(VCol,{staticClass:"col",attrs:{"xl":"3","md":"3","sm":"6","cols":"12"}},[_c(VSelect,{attrs:{"hide-details":"","outlined":"","dense":"","color":"var(--main-flamingo)","items":_vm.productOptions,"label":_vm.$i18n.t('product')},model:{value:(_vm.newOfferSelectedProduct),callback:function ($$v) {_vm.newOfferSelectedProduct=$$v},expression:"newOfferSelectedProduct"}})],1)],1),_c('div',{staticStyle:{"width":"100%","text-align":"right","padding-top":"10px"},attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c(VBtn,{staticClass:"bg-c-green c-white",attrs:{"size":"lg","disabled":!_vm.amountValid},on:{"click":_vm.addOffer}},[_vm._v(" "+_vm._s(_vm.$i18n.t('save'))+" ")])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }