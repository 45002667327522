<template>
    <v-main>
        <div class="main-content">
            <Card class="mb-15 card-custom">
              <template #title>
                <!-- <Toolbar>
                  <template #start>
                      <div class="d-flex align-center">
                        <h3>
                          {{ $t('geo_restrictions') }}
                        </h3>
                        
                      </div>
                  </template>
                  <template #end>
                    <span class="p-buttonset ml-2">
                      <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('clear')"
                        icon="pi pi-trash" class="p-button-sm p-button-danger" />
                      <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')"
                        icon="pi pi-search" class="p-button-sm p-button-success" />
                    </span>
                  </template>
              </Toolbar> -->
              <v-chip-group>
                <v-chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" :key="'filter' + key"
                  @click="removeFilter(key)">
                  <template>
                    {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                  </template>
                  <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                </v-chip>
              </v-chip-group>
              </template>
              <template #content>
                <v-row tag="section">
                  <!--  filterDisplay="row" -->
                  <v-col>
                    <DataTable 
                    :value="geoSettings.items" 
                    filterDisplay="row"
                    editMode="cell"
                    :editingRows.sync="editingRows"
                     @cell-edit-complete="onRowEditSave"
                    :rows="geoSettings.size"
                    :loading="loadingFlag" 
                    :selection.sync="selectedCountries"
                    responsiveLayout="scroll"
                      stripedRows 
                      class="p-datatable-sm">
                      <template #empty>
                        <div class="font-weight-bold">{{ $i18n.t('no_data') }} </div>
                     </template>
                      <!-- <Column selectionMode="multiple" :styles="{width: '3rem'}" :exportable="false">
                        <template #header>
                            <div class="mr-1">{{$t('select_all')}}</div>
                        </template>
                    </Column> -->
                      
                      <Column field="country_code"
                      :styles="{'width': '10rem'}"
                      :sortable="true"
                      >
                      <!-- :sortField="
                       data.country_code)" -->
                        <template #header>
                                <div class="d-flex flex-column align-start">
                                  <span>
                                      {{ $t('country_code') }}
                                  </span>
                                  <div class="subtitle-2" v-if="geoSettings && geoSettings.total">
                                      {{ `${geoSettings.total} - ${$t('countries')}` }}
                                  </div>
                                </div>
                        </template>
                        <template #body="{data}">
                            <div class="d-flex align-center">
                                <v-img
                                  max-height="20"
                                  max-width="20"
                                  :src="data.flag"
                                ></v-img>
                                <span class="ml-2">
                                    {{ data.country_code }}
                                </span>
                            </div>
                        </template>
                        
                      </Column>
                      <Column field="country_name" :showFilterMenu="false" :sortable="true"
                      :sortField="sortable"
                      :styles="{'width': '15rem'}"
                      >
                        <template #header>
                                <div>
                                    {{ `${$t('country')}/${$t('region')}` }}
                                </div>
                        </template>
                        <template #body="{data}">
                            <div>
                                    {{ data.country_name }}
                            </div>
                        </template>
                        <template #filter>
                          <InputText type="text"
                          @input="debounceFilters"
                          :placeholder="$t('country')" 
                          style="max-width: 200px"
                          v-model="filters.country_name" class="p-inputtext-sm"/>
                      </template>
                      </Column>
                      <Column :header="$t('allow')" :showFilterMenu="false" 
                      :styles="{'width': '10rem'}"
                      >
                        <template #body="{data}">
                             <span class="d-flex" style="max-width: 5rem;">
                               <v-switch value
                               :input-value="!data.blocked" @change="toggleGeo(data)" hide-details color="green accent-4"
                   inset class="mt-0 custom-switch "></v-switch>
                             </span>
                        </template>
                        <template #filter>
                         <TriStateCheckbox @change="applyFilters" v-model="filters.blocked" />
                         <!-- <Checkbox @change="checkboxesAllow(false)" class="checkbox-green" v-model="checkboxAllow" :binary="true" />
                         <Checkbox @change="checkboxesAllow(true)" class="checkbox-gray ml-2" v-model="checkboxBlocked" :binary="true" /> -->
                         </template> 
                       </Column>
                      <Column field="redirect_url" :header="$t('redirect_url')" :showFilterMenu="false" 
                       :styles="{'min-width': '25rem'}"
                      >
                        <template #body="{data}">
                         <div :class="{ 'redirect-url': data.redirect_url }">
                           <Button icon="pi pi-trash" 
                           v-if="data.redirect_url"
                           class="p-button-sm p-button-danger" @click.stop="deleteRedirectLink(data)" />
                           <span v-if="data.redirect_url">
                             {{ data.redirect_url }}
                           </span>
                           <i class="pi pi-pencil" v-else></i>
                         </div>
                        </template>
                        <template #editor="slotProps">
                         <InputText 
                          type="text"
                          v-model="slotProps.data[slotProps.column.field]" class="p-inputtext-sm fw" autofocus />
                     </template>
                     <template #filter>
                      <div style="width: 120px;">
                        <Button @click="deleteAllLinks" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('clear_all_links')"
                         class="p-button-sm p-button-danger" />
                      </div>
                      </template>
                       </Column>
                      
                      <Column :rowEditor="true" :exportable="false" :bodyStyle="{'text-align':'center'}"></Column>
                    </DataTable>
                    <games-paggination 
                              :pagginationObject="filters" 
                              :requestData="geoSettings" 
                              @apply-filters="setFilters()" 
                              :isStatic="false"
                              :allOptions="true"
                              />
                  </v-col>
                </v-row>
            </template>
            <template #footer>
            </template>
            </Card>
          
          </div>
    </v-main>
</template>

<script>
import { debounce } from 'lodash'
import GamesPaggination from '@/views/GamesPaggination'
import {mapGetters} from 'vuex'
    export default {
        name: 'GeoRestrictions',
        components:{
          GamesPaggination,
        },
        data() {
            return {
              activeFilters:{},
              countryFormValid: true,
              formLoading: false,
              editingRows:[],
                selectedCountries: null,
                loadingFlag: true,
                redirect_url: 'https://takemybet.pro/blocked',
                filters:{
                    // country: '',
                    blocked: null,
                },
                startPage: 1,
                page: 1,
                size: 25,
                filterPages: 25,
                checkboxAllow: false,
                checkboxBlocked: false,
            }
        },
        computed: {
          ...mapGetters({
            geoSettings: 'geo/getGeoSettings',
            gameFilters: 'paggination/getFilters',
          }),
          filteredActiveFilters() {
          return Object.fromEntries(
            Object.entries(this.activeFilters).filter(
              ([key, value]) => key !== 'size' && key !== 'page' && (key === 'is_active' || value !== null)
            )
          );
        },
        },
        async mounted(){
            await this.getAllGeo();
        },
        beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'geo_restrictions')
    });
    },
    methods:{
      checkboxesAllow(block){
        if (block) {
          this.checkboxAllow = false;
          this.checkboxBlocked = true;
        } else {
          if (this.checkboxAllow) {
            
            this.checkboxAllow = false;
            } else{
            this.checkboxAllow = true;

          }
          this.checkboxBlocked = false;
        }
        this.filters.blocked = block;
      },
      sortable(column){
      },
      debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
      getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'country_name':
          return this.$t('country')
        case 'blocked':
          return this.$t('blocked')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }

      return value;
    },
      async clearFilters() {
      this.loadingFlag = true;
      Object.keys(this.filters).forEach((key, index) => {
        // this.filters[key] = ''
        this.$delete(this.filters, key);
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      this.filters.size = this.filterPages;
      this.filters.page = this.page = this.startPage;
      this.setFilters();
    },
      async getAllGeo(){
        this.filters.page = this.page = this.startPage;
        this.filters.size = this.size = this.filterPages;
        try {
          await this.$store.dispatch('geo/awaitGetGeoSettings', this.filters);
          
        } catch (error) {
          this.$toast.open({
            summary: this.$t("just_error"),
            life: 4000,
            severity: 'error',
          })
        } finally{
          this.$store.commit('paggination/SET_FILTERS', this.filters);
          this.loadingFlag = false;
          this.$store.commit('paggination/SET_LOADING_FLAG', false);
        }
      },
      async applyFilters(){
        this.loadingFlag = true;
        this.filters.page = this.page = this.startPage;
        this.filters.size = this.size = this.filterPages;
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        try {
          await this.$store.dispatch('geo/awaitGetGeoSettings', this.filters);
          
        } catch (error) {
          this.$toast.open({
            summary: this.$t("just_error"),
            life: 4000,
            severity: 'error',
          })
        } finally{
        this.holdActiveFilters();
        this.loadingFlag = false;
        }
      },
      holdActiveFilters(){
        for (const [key, value] of Object.entries(this.filters)) {
          if (value !== '') {
            this.$set(this.activeFilters, key, value);
          } else {
            this.$delete(this.activeFilters, key, value);
          }
        }
      },

      async setFilters(){
        this.loadingFlag = true;
        this.$store.commit('paggination/SET_LOADING_FLAG', true);
        try {
          await this.$store.dispatch('geo/awaitGetGeoSettings', this.gameFilters);
          
        } catch (error) {
          this.$toast.open({
            summary: this.$t("just_error"),
            life: 4000,
            severity: 'error',
          })
        } finally{
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.holdActiveFilters();
        this.loadingFlag = false;
        this.$store.commit('paggination/SET_LOADING_FLAG', false);
        }
      },
      async onRowEditSave(event) {
        let { data, newData, index } = event;
        // console.log(newData.redirect_url);
        if (newData) {
          if (newData.redirect_url) {
            
            const trimmedUrl = newData.redirect_url.trim();
    if (!trimmedUrl) {
        this.$toast.add({
            severity: 'error',
            summary: this.$t("invalid_url"),
            detail: this.$t('invalid_url_detail'),
            life: 4000
        })
        return;
    } else if (!trimmedUrl.match(/^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s/?#]+(?:\/[^\s/?#]*)?$/)) {
        this.$toast.add({
            severity: 'error',
            summary: this.$t("invalid_url"),
            detail: this.$t('invalid_url_detail'),
            life: 4000
        })
        return;
    }
          
        } else{
          return
        }
        }
        // console.log('newData', newData);
        // console.log('data', data);
        if (newData.redirect_url === data.redirect_url) {
          return;
        }
        this.updateGeoCountry(newData);
        },
        async updateGeoCountry(data){
          this.loadingFlag = true;
        const res = await this.$store.dispatch('geo/updateGeoSetting', data);
        if (res) {
          this.$toast.add({
            severity: 'success', summary: this.$t('updated_geo'), life: 4000
          })
          await this.$store.dispatch('geo/awaitGetGeoSettings', this.filters);
        } else{
          this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          })
        }
        this.holdActiveFilters();
        this.loadingFlag = false;
        },
      async toggleGeo(country){
        this.loadingFlag = true;
        const res = await this.$store.dispatch('geo/updateActiveGeo', country);
        if (res) {
          this.$toast.add({
            severity: 'success', summary: this.$t('updated_geo'), life: 4000
          })
          // this.getAllGeo();
          await this.$store.dispatch('geo/awaitGetGeoSettings', this.filters);
        } else{
          this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          })
        }
        this.loadingFlag = false;
      },
      async deleteRedirectLink(country){
      // console.log(country);
        country.redirect_url = null;
        this.updateGeoCountry(country)
        
      },
      async deleteAllLinks(){
        this.loadingFlag = true;
        try {
          const ids = this.geoSettings.items.map(country => country.id);
          await this.$store.dispatch('geo/deleteGeoSetting', ids);
      this.$toast.add({
        severity: 'success', summary: this.$t('updated_geo'), life: 4000
      })
      await this.$store.dispatch('geo/awaitGetGeoSettings', this.filters);
    } catch (error) {
      this.$toast.add({
        severity: 'error', summary: this.$t("just_error"), life: 4000
      })
    } finally{
      this.loadingFlag = false;
    }
      },
      toggleAllCountries(event){
        if (event === false) {
          this.countries = this.countries.map(country => {
    return {
      ...country,
      redirect_url: 'https://takemybet.pro/blocked'
    };
  });
        } else{
          this.countries = this.countries.map(({ redirect_url, ...rest }) => rest);
        }
      },
      async removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$delete(this.activeFilters, key);
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      if (key === 'blocked') {
        this.$set(this.filters, key, null);
      }
      this.setFilters();
    },
    },
    }
</script>

<style lang="scss" scoped>
.redirect-url{
  display: flex;
  //align-items: center;
  //width: 100%;
  border: 1px solid var(--red);
  transition: all 0.3s;
  .p-button-sm{
    flex: 0 0 30px;
    padding: 0.5rem;
  border-radius: 0;
  }
  @media (any-hover: hover){
    &:hover{
    span{

      border-color: #DC2626;
    }
    }
  }
  span{
    align-self: center;
  margin: 0 5px;
  flex: 1 1 100%;
  }
}
</style>