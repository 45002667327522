<template>
  <div>

    <!--
    <b-table
            striped
            hover
            :items="$store.getters.getPlayers"
            :fields="fields"
            @row-clicked="playerClick"
            id="players_table_id"
            style="margin-top: 10px"
            head-variant="dark"
            sticky-header="58vh"
            :tbody-tr-class="rowClass"
            :busy="loadingFlag"
            show-empty
    >

        <template v-slot:cell(block)="data">

            <div v-if="data.item.block == 0">
                Активен
            </div>
            <div v-if="data.item.block == 1">
                Неактивен
            </div>
        </template>
        <template v-slot:empty="scope">
            <h4>Офферы, удовлетворяющие запросу, не найдены</h4>
        </template>
    </b-table>
    -->
    <!--<v-container style="max-height: 80vh;max-width: none;overflow-y: scroll;margin-top: 10px;">
      <v-row >
        <v-col v-for="(request, i) in $store.getters.affiliateRequests" v-bind:key="i.id" v-if="!loadingFlag" style="justify-content: center;max-width: 33%">
          <v-card style="max-width: 300px;min-width: 300px">
            <v-card-title>
              Запрос на регистрацию аффилейта
            </v-card-title>
            <v-card-text>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col>
                  ID
                </v-col>
                <v-col>
                  {{request.id}}
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col>
                  Email
                </v-col>
                <v-col>
                  {{request.email}}
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col>
                  Telegram
                </v-col>
                <v-col>
                  {{request.telegram}}
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  Skype
                </v-col>
                <v-col>
                  {{request.skype}}
                </v-col>
              </v-row>
              <v-row style="margin-top: 10px">
                <v-col style="text-align: right">
                  <v-btn variant="success" @click="selectAffiliate(request)" style="text-transform: none">
                    Подтвердить
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="$store.getters.affiliateRequests.length === 0">
          Запросы отсутствуют
        </v-col>
      </v-row>

      <div class="text-center my-2" v-if="loadingFlag">
        <v-progress-circular indeterminate color="primary" size="100">
          загрузка
        </v-progress-circular>
      </div>
    </v-container>-->
    <v-data-table
        :items="$store.getters.affiliateRequests"
        :headers="headers"
        :no-data-text="$i18n.t('requests_not_available')"
        :loading-text="this.$t('loading') + '...'"
        :footer-props="{'items-per-page-options': [10, 25, 100, -1],'items-per-page-text': this.$i18n.t('pages')}"
        :loading="loadingFlag"
    >
      <template v-slot:item.action="{ item }">
        <div>
          <v-btn @click="selectAffiliate(item)">
            <v-icon>check</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="affiliateOffersModalFlag" max-width="fit-content" style="border-radius: 10%">
      <v-card style="min-width: 300px">
        <v-card-title>
          <h3>{{ $i18n.t('affiliate_confirmation') }}</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col style="text-align: center">
              <v-label>
                {{ $i18n.t('select_offers') }}:
              </v-label>
              <v-checkbox
                  v-for="(offer, index) in offerOptions"
                  :key="index"
                  :value="offer.value"
                  :label="offer.text"
                  v-model="selectedOffers"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  :label="$i18n.t('manager')"
                  v-model="affManager"
                  :items="$store.getters.affManagers"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn size="lg" @click="activateAffiliate" style="margin-left: auto;text-transform: none">
            {{ $i18n.t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "OffersTable",
  data() {
    return {
      selectedTab: null,
      headers: [
        {value: 'id', text: 'ID', sortable: true,},
        {value: 'email', text: 'Email', sortable: true},
        {value: 'skype', text: 'Skype', sortable: true},
        {value: 'telegram', text: 'Telegram', sortable: true},
        {value: 'action', text: this.$i18n.t('actions'), sortable: false},
      ],
      loadingFlag: false,
      loadingModalFlag: false,
      offerOptions: [],
      affiliateOffersModalFlag: false,
      selectedAffiliate: null,
      modalPlayer: {},
      selectedOffers: [],
      affManager: ''
    }
  },

  async mounted() {
    this.loadingFlag = true
    await this.applyFilters()
    await this.$store.dispatch('getAffiliateOffers')
    for (let offer of this.$store.getters.affiliateOffers) {
      this.offerOptions.push({value: offer.id, text: `${offer.offer_type} ${offer.product}`})
    }
    await this.$store.dispatch('getAffiliatesRequests')
    this.loadingFlag = false
  },
  computed: {
    amountValid() {
      return this.newOfferAmount >= 10 && this.newOfferAmount <= 90
    }
  },
  methods: {
    async playerClick(player) {

    },
    selectAffiliate(affRequest) {
      this.affiliateOffersModalFlag = true;
      this.selectedAffiliate = affRequest;
    },
    async activateAffiliate() {
      this.loadingModalFlag = true
      //activateAffiliate

      let res = await this.$store.dispatch('activateAffiliate', {
        id: this.selectedAffiliate.id,
        offers: this.selectedOffers,
        manager: this.affManager
      });
      if (res) {
        this.affiliateOffersModalFlag = false
      }
      this.loadingModalFlag = false

    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.block === 1) {
        return 'table-danger'
      } else {
        return 'table-success'
      }
    },
    async applyFilters() {
      //this.loadingFlag = true
      //await this.$store.dispatch('playersFilters', {filters: this.filters});
      //this.loadingFlag = false
    },
    async clearFilters() {

      //await this.$store.dispatch('playersFilters', {filters: this.filters});
    }
  }
}
</script>

<style scoped>

</style>
