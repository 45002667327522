<template>
  <div>
    <v-btn
        icon
        small
        :style="{backgroundColor: this.isRegistered ? 'var(--green-success)' : 'var(--main-flamingo)'}"
        @click="call"
        v-if="!isCallInProgress"
    >
      <v-icon color="white" style="font-size: 18px;">phone</v-icon>
    </v-btn>
    <div v-if="isCallInProgress">
      {{ $i18n.t('call_in_progress') }}
    </div>
    <v-dialog v-model="signDialog" max-width="fit-content" content-class="br-16" v-if="!isRegistered">
      <v-card style="min-width: 320px">
        <v-card-title>
          {{ $i18n.t('authorization') }}
        </v-card-title>
        <v-card-text>
          <v-text-field class="mb-2" hide-details dense outlined color="var(--main-flamingo)" :label="$i18n.t('login')" v-model="username"></v-text-field>
          <v-text-field class="mb-2" hide-details dense outlined color="var(--main-flamingo)" :label="$i18n.t('password')" type="password" v-model="password"></v-text-field>
          <v-btn
              style="text-transform: uppercase"
              color="main-btn"
              @click="connect"
              :loading="isConnecting"
          >
            {{ $i18n.t('sign_in') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "PlayerPhoneWidget",
  props: {
    player: {
      required: true,
      default: null,
    }
  },
  computed: {
    ...mapGetters({
      isRegistered: 'phone/isRegistered',
      isCallInProgress: 'phone/isCallInProgress',
      isConnecting: 'phone/isConnecting',
    })
  },
  data() {
    return {
      signDialog: false,
      username: '',
      password: '',
    }
  },
  async mounted() {
    //this.sipPhone = new SipPhone();
    //await this.sipPhone.register();
  },
  methods: {
    async call() {
      if (!this.isRegistered) {
        this.signDialog = true;
      } else if (this.player.tel) {
        await this.$store.dispatch('phone/call', {
          number: this.player.tel,
          playerId: this.player.id
        });
      }
      //await this.$store.dispatch('phone/connect', {login: '253864-100', password: '2y84kSbeYn'});
      //await this.sipPhone.makeCall();
    },
    async connect() {
      await this.$store.dispatch('phone/connect', {
        login: this.login,
        password: this.password,
      });
      //this.$store.commit('phone/registeredHandler');
    },

  }
}
</script>

<style scoped>

</style>
