<template>
  <div>
    <Card class="br-0">
      <template #content>  
            <div class="d-flex align-center space-x-1">
              <Button @click="toCreateNotification" 
              :disabled="loadingFlag || !allowedAddComment" 
              :loading="loadingFlag"
              v-tooltip.right="!allowedAddComment ? $t('no_permission') : $t('tooltip_add_notification')"  
              :label="$t('create_notification')" icon="pi pi-plus" class="p-button-sm p-button-success" />
              <Button @click="applyFilters" 
              v-tooltip.right="$t('update')"  
              :disabled="loadingFlag" 
              :loading="loadingFlag"
               icon="pi pi-refresh" 
               class="p-button-sm p-button-danger" 
               />
            </div>
            <v-chip-group v-if="filteredActiveFilters">
              <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key">
                <template>
                {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                </template>
                <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
              </v-chip>
            </v-chip-group>
      </template>
      </Card>
      <DataTable 
      :value="notifications" 
      :rows="10"
      :loading="loadingFlag" 
      filterDisplay="row"
      :scrollable="true"
      :paginator="true"
        class="p-datatable-sm"
        >
        <template #empty>
         {{ $i18n.t('notifications_not_found') }}
      </template>
        <Column field="id" header="ID" :showFilterMenu="false" :styles="{'min-width':'5rem'}">
          <template #body="{data}">
            <b>
              {{ data.id }}
            </b>
        </template>
        <template #filter>
        </template>
        </Column>
        <Column field="type" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width':'5rem'}">
          <template #body="{data}">
            <span class="custom-badge"
            :class="getTypeClass(data.type)"
            >
              <span>{{ getType(data.type) }} </span>
            </span>
        </template>
        <template #filter>
        </template>
        </Column>
        <Column field="message" :header="$t('message')" :showFilterMenu="false" :styles="{'min-width':'10rem'}">
          <template #body="{data}">
              <span>{{ data.message }} </span>
        </template>
        <template #filter>
        </template>
        </Column>
        <Column field="is_viewed" :header="$t('is_viewed')" :showFilterMenu="false" :styles="{'min-width':'10rem'}">
          <template #body="{data}">
              <span :class="getIsViewedClass(data.is_viewed)">{{ getIsViewed(data.is_viewed) }} </span>
        </template>
        <template #filter>
        </template>
        </Column>
        <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'max-width':'25rem'}">
          <template #body="{data}">
            <div style="white-space: nowrap">
              {{ new Date(data.created_at).toLocaleString() }}
            </div>
        </template>
        <template #filter>
          <!-- <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            
            <InputText
            v-on="on"
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="$t('date')"
                  v-model="getFilteredDates"
                  dateFormat="mm.dd.yy"
                  >
                </InputText>
          
          </template>
          <Calendar class="calendar-menu"  
          @date-select="applyFilters"
          :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="multiple" />
        </v-menu> -->
        </template>
        </Column>
        <Column :exportable="false" 
                    :header="$t('actions')"
                    
                    >
                      <template #body="{data, index}">
                          <div class="d-flex align-center">
                            <Button icon="pi pi-pencil" 
                            :disabled="data.loadingItem" :loading="data.loadingItem" 
                            class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toEditNotification(data)" />
                            <Button icon="pi pi-trash" 
                            :disabled="data.loadingItem" :loading="data.loadingItem" 
                            class="p-button-sm p-button-rounded p-button-danger" 
                            aria:haspopup="true" :aria-controls="`overlay_panel_${index}`"
                            @click="toDeleteNotification(index)" />
                                          <!-- class="admin-overlay" -->
                                          <OverlayPanel :ref="`op_${index}`" appendTo="body" :id="`overlay_panel_${index}`" class="p-confirm-popup">
                                            <div class="p-confirm-popup-content">
                                              <i class="p-confirm-popup-icon pi pi-exclamation-triangle"></i>
                                              <div class="p-confirm-popup-message d-flex flex-column" 
                            style="max-width:400px"
                            >
                                <h3>{{ $t('attention_title') }}</h3>
                                <span>{{ $t('notification_will_deleted') }}</span>
                                <h4 style="margin-top: 5px;">{{ $t('attention_question') }}</h4>
                            </div>
                                            </div>
                                            <div class="p-confirm-popup-footer">
                                                <span class="p-buttonset">
                                                    <Button @click="toCloseOverlay(index)" :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                    :label="$t('no')"
                                                     class="p-button-sm p-button-warning" />
                                                    <Button @click="toConfirmDelete(data, index)" 
                                                    :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                    :label="$t('confirm')"
                                                      class="p-button-sm p-button-danger" />
                                                  </span>
                                            </div>
                                          </OverlayPanel>
                         
                          </div>
                        </template>
                  </Column>
      </DataTable>
    <v-dialog v-model="notificationModal" content-class="br-16" max-width="500">
      <v-card>
        <v-card-title class="pb-0 justify-center"> 
          <h3>{{ $i18n.t( isEditNotification ? 'edit_notification' : 'new_notification') }}</h3>
          <close-modal-button 
          @close-modal="notificationModal = false"
          />
  </v-card-title>
        <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
          <v-card-text class="pb-0 pt-1 text-left space-y-2">
                          <ValidationProvider
                          v-if="!isEditNotification"
                  :name="$t('type')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('type') }}<span class="c-red">*</span>:</h4>
                  <div class="d-flex flex-column">
                    <!-- :minFractionDigits="2" -->
                    <v-menu v-model="notificationMenu" 
                    offset-y
                    :close-on-content-click="false" 
                        nudge-left="0" 
                        nudge-right="0"
                        
                        >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-column" v-on="on">
                          <span class="p-input-icon-right"  >
                            <i class="pi pi-angle-down" />
                              <InputText 
                              readonly
                              v-model="notification.type"
                               class="p-inputtext-sm fw" :placeholder="$t('type')">
                              </InputText>
                              </span>
                              </div>
                        </template>
                          <Listbox v-model="notification.type" :options="types"
                          @change="notificationMenu = false"
                          :filter="true"
                          class="custom-dropdown"
                          :emptyFilterMessage="$t('no_data')"
                          optionLabel="name" 
                          optionValue="value"
                          
                          style="width:auto">
                          </Listbox>
            
                      </v-menu>
                    <span v-if="typeError" class="p-error mt-1 font-weight-bold">{{ $t('required_field') }}</span>
                  </div>
              </ValidationProvider>
                          <ValidationProvider
                  :name="$t('message')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('message') }}<span class="c-red">*</span>:</h4>
                  <div class="d-flex flex-column">
                    <!-- :minFractionDigits="2" -->
                    <Textarea v-model="notification.message" 
                  :autoResize="true" rows="5" class="fw" />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <Button @click="handleSubmit(validate)" :loading="formLoading" 
            :disabled="formLoading" 

              :label="$t(isEditNotification ? 'edit' : 'add')" icon="pi pi-save"

              :class="['p-button-sm fw', {'p-button-success': !isEditNotification, 'p-button-warning': isEditNotification}]"
              
              />
          </v-card-actions>
      </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "NotificationsTab",
    props: {
      playerId: {
        required: true,
        default: null,
      }
    },
    watch:{
      datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.dateMenu = false;
              this.applyFilters()
            }
          },
    },
    computed: {
      ...mapGetters({
        permissions: 'roles/elementsPlayerPermission',
        notifications: 'notifications/getNotifications',
      }),
            allowedAddComment() {
              if (this.permissions) {
                if (this.permissions.includes('add_notifications') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
      filteredActiveFilters() {
        const filtersLength = Object.keys(this.activeFilters).length;
        // console.log("filtersLength", filtersLength);
        if (filtersLength >= 2) {
    return Object.fromEntries(
      Object.entries(this.activeFilters).filter(
        ([key, value]) => key !== 'size' && key !== 'page' && key !== 'user_id'
      )
    );
  } else{
    return null
  }
        },
        getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            console.log('this.datesToFilter[1]', this.datesToFilter[1]);
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
    },
    data(){
      return{
        loadingFlag: false,
        formLoading: false,
        typeError: false,
        notificationModal: false,
        notificationMenu: false,
        notification: {
          type: 'MESSAGE',
          message: '',
        },
        isEditNotification: false,
        types: [
          { name: this.$t('level'), value: 'LEVEL' },
          { name: this.$t('message'), value: 'MESSAGE' },
          { name: this.$t('bonus'), value: 'BONUS' },
          { name: this.$t('ban'), value: 'BAN' },
          { name: this.$t('compensation'), value: 'COMPENSATION' },
        ],
        today: new Date(),
        dateMenu: false,
        datesToFilter: [],
        activeFilters: {},
        currentLocale: this.$i18n.locale || 'en',
        filters:{
          min_date: '',
          max_date: '',
          user_id: '',
        },
      }
    },
    async mounted() {
      await this.applyFilters()
    },
    methods:{
      toEditNotification(notification){
        this.isEditNotification = true;
        this.notificationModal = true;
        this.notification = {...notification};
        this.$nextTick().then(() => {
          this.$refs.observer.reset();
        })
      },
      toCreateNotification(){
        this.isEditNotification = false;
        this.notification = {
          type: 'MESSAGE',
          message: '',
        }
        this.notificationModal = true;
        this.$nextTick().then(() => {
          this.$refs.observer.reset();
        })
      },
      handleSubmit(validate) {
        validate().then(valid => {
          this.typeError = false
          if (valid) {
            if (!this.notification.type) {
              this.typeError = true
            } else{
              if (this.isEditNotification) {
                this.editNotification();
              }else{
                this.addNotification();
              }
            }
          }
        })
      },
      async toConfirmDelete(notif, index){
        notif.loadingItem = true;
                try {
                    await this.$store.dispatch('notifications/awaitDeleteNotification', notif.id);
                    this.$toast.add({ severity: 'success', summary: this.$t('notification_deleted'), life: 4000 });
                    await this.applyFilters();
                } catch (error) {
                        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
                } finally{
                    this.$refs[`op_${index}`].toggle(event);
                    notif.loadingItem = false;
                }
            },
      toCloseOverlay(index){
          this.$refs[`op_${index}`].hide(event);
      },
      toDeleteNotification(index){
                this.$refs[`op_${index}`].toggle(event);
            },
      async addNotification(){
        this.formLoading = true;
        try {
          const notif = this.notification
          const notificationData = {
            user_id: this.playerId,
            type: notif.type,
            message: notif.message
          }
            await this.$store.dispatch('notifications/awaitCreateNotificationToPlayer', notificationData);
            this.$toast.add({
              severity: 'success',
              summary: this.$t('notification_added'),
              life: 4000
            })
            await this.applyFilters();
            this.notificationModal = false;
            this.notification = {
              type: 'MESSAGE',
              message: '',
            }
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('just_error'),
              life: 4000})
          } finally{
            this.formLoading = false;
          }
      },
      async editNotification(){
        this.formLoading = true;
        try {
          const notif = this.notification
          const notificationData = {
            type: notif.type,
            message: notif.message,
            id: notif.id
          }
            await this.$store.dispatch('notifications/awaitUpdateNotification', notificationData);
            this.$toast.add({
              severity: 'success',
              summary: this.$t('notification_edited'),
              life: 4000
            })
            await this.applyFilters();
            this.notificationModal = false;
            this.isEditNotification = false;
            this.notification = {
              type: 'MESSAGE',
              message: '',
            }
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('just_error'),
              life: 4000})
          } finally{
            this.formLoading = false;
          }
      },
      //========================================================================================================================================================
    async setAndSubmitFilters(){
      this.loadingFlag = true;
      try {
        await this.$store.dispatch('notifications/awaitGetNotificationsByPlayer', this.playerId);
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), 
        detail: error.message,
        life: 4000 });
      } finally{
        this.loadingFlag = false;
      }
        //for (const [key, value] of Object.entries(this.filters)) {
        //if (value !== '') {
        //  this.$set(this.activeFilters, key, value);
        //} else {
        //  this.$delete(this.activeFilters, key);
        //}
    },
      async applyFilters(){
        this.filters.user_id = this.playerId;
        this.getFormattedDate();
        this.setAndSubmitFilters();
      },
      getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.min_date = formattedDateOne;
            this.filters.max_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.min_date = formattedDateOne;
          }
        }
    },
    //========================================================================================================================================================
    
    getIsViewedClass(is_viewed) {
      switch (is_viewed) {
        case true:
          return '_green';
        case false:
          return '_gray';
      }
    },
    getIsViewed(is_viewed) {
      switch (is_viewed) {
        case true:
          return this.$t('yes');
        case false:
          return this.$t('no');
      }
    },
    getTypeClass(type) {
      switch (type) {
        case 'BONUS':
          return '_green';
        case 'LEVEL':
          return '_gray';
          case 'MESSAGE': 
            return '_main';
            case 'BAN': 
              return '_red';
              case 'COMPENSATION':
                return '_yellow';
      }
    },
    getType(type) {
      switch (type) {
        case 'BONUS':
          return this.$t('bonus');
        case 'LEVEL':
          return this.$t('level');
          case 'MESSAGE': 
            return this.$t('message');
            case 'BAN': 
              return this.$t('ban');
              case 'COMPENSATION':
                return this.$t('compensation');
      }
    },
      //========================================================================================================================================================
      
      getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'is_active':
          return this.$t('status')
        case 'min_date':
          return this.$t('date_from')
        case 'max_date':
          return this.$t('date_to')
      }
      return key;
    },
    getFilterValueText(key, value) {
      // chip values

      return value;
    },
    removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$delete(this.activeFilters, key);
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      if (key === 'min_date') {
        this.datesToFilter.splice(0, 1);
      }
      if (key === 'max_date') {
        this.datesToFilter.splice(1, 1); // $delete(this.datesToFilter[1]);
      }
      this.setAndSubmitFilters();
    },
    }
  }
</script>

<style lang="scss" scoped>
</style>
