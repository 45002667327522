<template>
  <div>
    <v-row style="padding: 10px">
      <v-col style="text-align: left">
        <v-btn size="lg" class="bg-c-red c-white" @click="offerModalFlag = true" style="text-transform: none">
          <v-icon>add</v-icon>
          {{ $i18n.t('add_new') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :items="$store.getters.affiliateOffers"
        :headers="fields"
        fixed-header
        :no-data-text="$i18n.t('requests_not_available')"
        :loading-text="this.$t('loading') + '...'"
        :loading="loadingFlag"
    >

    </v-data-table>


    <v-dialog v-model="offerModalFlag" persistent content-class="br-16"
              max-width="900">
      <v-card>
        <v-card-title class="py-1">
          <h5>{{ $i18n.t('add_offer') }}</h5>
          <v-spacer></v-spacer>
          <v-btn @click="offerModalFlag = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div>
            <v-row>
              <v-col xl="3" md="3" sm="6" cols="12" class="col">
                <v-select
                    v-model="newOfferType"
                    hide-details outlined dense color="var(--main-flamingo)"
                    :items="[
                      { value: 'REVENUE_SHARE', text: $i18n.t('revenue_share') },
                      { value: 'CPA', text: $i18n.t('cost_per_acquisition') },
                      { value: 'TIERED_REVENUE_SHARE', text: $i18n.t('tiered_revenue_share') }
                    ]"
                    :label="$i18n.t('type')"
                ></v-select>
              </v-col>
              <v-col xl="3" md="3" sm="6" cols="12" class="col">
                <v-text-field
                hide-details outlined dense color="var(--main-flamingo)"
                    :label="$i18n.t('rate')"
                    v-model.number="newOfferFee"
                    type="number" step="1" min="10" max="90"
                    :state="amountValid"
                ></v-text-field>
              </v-col>
              <v-col xl="3" md="3" sm="6" cols="12" class="col">
                <v-select
                hide-details outlined dense color="var(--main-flamingo)"
                    :items="productOptions"
                    v-model="newOfferSelectedProduct"
                    :label="$i18n.t('product')"
                ></v-select>
              </v-col>
            </v-row>
            <div slot="modal-footer" style="width: 100%;text-align: right;padding-top: 10px">
              <v-btn size="lg" class="bg-c-green c-white" @click="addOffer" :disabled="!amountValid">
                {{ $i18n.t('save') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
  name: "OffersTable",
  data() {
    return {
      selectedTab: null,
      fields: [
        {value: 'product', text:  this.$i18n.t('product'), sortable: true},
        {value: 'offer_type', text:  this.$i18n.t('offer'), sortable: true},
        {value: 'fee', text:  this.$i18n.t('rate'), sortable: true},
        // { key: 'block', label: 'Ограничения трафика', sortable: false }
      ],
      loadingFlag: false,
      loadingModalFlag: false,
      options: [
        {value: 0, text:  this.$i18n.t('active')},
        {value: 1, text:  this.$i18n.t('not_active')}
      ],
      offerModalFlag: false,
      modalPlayer: {},
      newOfferFee: 10,
      newOfferType: 1,
      newOfferSelectedProduct: 'Winstar Web',
      productOptions: [
        {value: 'Winstar Web', text: 'BLESSED Web'},
        {value: 'Winstar Bot', text: 'BLESSED Bot'},
      ]
    }
  },

  async mounted() {
    if (this.$store.getters.affiliateOffers.length === 0) {
      this.loadingFlag = true;
      await this.$store.dispatch('getAffiliateOffers');
      this.loadingFlag = false;
    }

  },
  computed: {
    amountValid() {
      return this.newOfferFee >= 0 && this.newOfferFee <= 100;
    }
  },
  methods: {
    async addOffer() {
      this.loadingModalFlag = true;
      //
      let res = await this.$store.dispatch('createOffer', {
        type: this.newOfferType,
        product: this.newOfferSelectedProduct,
        fee: this.newOfferFee
      });
      this.loadingModalFlag = false;
      if (res) {
        this.offerModalFlag = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
