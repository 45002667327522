import { render, staticRenderFns } from "./AuthTab.vue?vue&type=template&id=76436ede&scoped=true"
import script from "./AuthTab.vue?vue&type=script&lang=js"
export * from "./AuthTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76436ede",
  null
  
)

export default component.exports