<template>
    <div>
        <v-card class="card_style" style="">
            <v-card-text class="card_body_style">
                <v-row>
                    <v-col cols="6" class="label_style">
                      {{ $i18n.t('search_by') }} {{ $i18n.t('affiliate') }}:
                    </v-col >
                    <v-col cols="2" class="label_style">
                      {{ $i18n.t('status') }}
                    </v-col>
                    <v-col class="label_style_center">
                      {{ $i18n.t('balance') }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="filters.affiliate_id" ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <b-form-select v-model="filters.status"  :options="options"></b-form-select>
                    </v-col>
                    <v-col>
                        <v-text-field placeholder="от" v-model="filters.min_balance"></v-text-field>

                    </v-col>
                    <v-col>
                        <v-text-field placeholder="до" v-model="filters.max_balance"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="label_style">
                      {{ $i18n.t('tags') }}
                    </v-col >
                    <v-col class="label_style_center">
                      {{ $i18n.t('reg_date') }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field  disabled></v-text-field>
                    </v-col>
                    <v-col >
                        <b-input-group size="md">
                            <template v-slot:append>
                                <b-icon-calendar class="h1"></b-icon-calendar>
                            </template>
                            <template v-slot:prepend>
                                <div style="padding: 8px">
                                    С
                                </div>
                            </template>
                            <v-text-field type="date" v-model="filters.min_reg_date"></v-text-field>
                        </b-input-group>
                    </v-col>
                    <v-col >
                        <b-input-group size="md">
                            <template v-slot:prepend>
                                <div style="padding: 8px">
                                    по
                                </div>
                            </template>
                            <template v-slot:append>
                                <b-icon-calendar class="h1"></b-icon-calendar>
                            </template>
                            <v-text-field type="date" v-model="filters.max_reg_date"></v-text-field>
                        </b-input-group>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-footer style="text-align: right;padding-bottom: 0px">
                <v-row style="align-content: space-between">
                    <v-col>
                        <v-btn size="lg" @click="clearFilters">{{ $i18n.t('clear') }}</v-btn>
                    </v-col>
                    <v-col cols="2">
                        <v-btn size="lg" @click="applyFilters">{{ $i18n.t('apply') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-footer>
        </v-card>
        <b-table
                striped
                hover
                :items="$store.getters.getPlayers"
                :fields="fields"
                @row-clicked="playerClick"
                id="players_table_id"
                style="margin-top: 10px"
                head-variant="dark"
                sticky-header="58vh"

                :busy="loadingFlag"
                show-empty
        >

            <template v-slot:cell(block)="data">
                <!--
                <select class="custom-select" v-model="data.item.block" :style="data.item.block == 1?'color: red':'color: green'">
                    <option v-for="option in options" :style="option.value == 1?'color: red':'color: green'" :value="option.value">
                        {{option.text}}
                    </option>
                </select>
                -->
                <div v-if="data.item.block == 0">
                  {{ $i18n.t('active') }}
                </div>
                <div v-if="data.item.block == 1">
                  {{ $i18n.t('not_active') }}
                </div>
            </template>
            <template v-slot:empty="scope">
                <h4>{{ $i18n.t('players_not_found') }}</h4>
            </template>
        </b-table>



    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //{ Player: 'Player 1', Affiliate: '24142xdf', Registration: '20.02.2012', Balance: '201232 RUB', Status:'Не активен'},

    export default {
        name: 'PlayersTab',
        components:{

        },
        data(){
            return{
                fields: [
                    { key: 'id', label:  this.$i18n.t('palyer'), sortable: false },
                    // { key: 'affiliate_id', label: 'Аффилейт', sortable: false },
                    // { key: 'reg_date', label: 'Зарегистрирован', sortable: false },
                    // { key: 'balance', label: 'Баланс', sortable: false },
                    // { key: 'block', label: 'Статус', sortable: false }
                ],
                loadingFlag: false,
                loadingModalFlag:false,
                options:[
                    {value: 0, text:  this.$i18n.t('active')},
                    {value: 1, text:  this.$i18n.t('not_active')}
                ],
                playerModalFlag: false,
                modalPlayer:{},
                filters:{
                    affiliate_id: '',
                    status: '',
                    min_balance: '',
                    max_balance: '',
                    min_reg_date:'',
                    max_reg_date:'',
                }
            }
        },
        async mounted(){
            await this.applyFilters()
        },
        methods:{
            async playerClick(player){

            },
            rowClass(item, type) {


            },
            async applyFilters(){

            },
            async clearFilters(){
                Object.keys(this.filters).forEach((key, index) =>{
                    this.filters[key] = ''
                })
                //await this.$store.dispatch('playersFilters', {filters: this.filters});
            }
        }
    }
</script>
<style lang="scss">

    .card_style{
        margin-top: 10px;
        box-shadow: 0 0 5px #2c3e50;
        border-radius: 20px!important;
    }
    .card_header_style{
        text-align: left;
        font-size: 26px;
        font-weight: 400;
        padding: 10px;
    }
    .card_body_style{
        background-color: #0f0f0f0f;

    }
    .label_style{
        text-align: left;
        font-weight: 100;
        font-size: 20px;
    }
    .label_style_center{
        font-weight: 100;
        font-size: 20px;
    }
    .modal_th{
        font-size: 20px;
    }
    .tabs_active{
        background-color: #2c3e50;
    }

    .modal-xl {
        width: 90% !important;
        //max-width: max-content!important;
        font-family: Gilroy;
        .modal-header{

            padding-bottom: 0px;
        }
        .modal-title{
            width: 80%
        }
        .modal-body{
            padding-bottom: 0px;
            padding-top: 0px;
        }
        .modal-footer{
            border-top: none;
            padding-top: 0px;
        }
    }

    #players_table_id{
        tbody{
            font-size: 18px;
            cursor: pointer;
        }
        th{
            font-size: 20px;
        }
    }

</style>