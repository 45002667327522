<template>
  <div>
      <!-- hide-default-footer -->
      <Button @click="returnButton" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('return_to_list')" icon="pi pi-angle-left" class="p-button-sm p-button-danger my-1" />
      <Panel class="mx-1">
        <template #header>
          <div class="space-x-1">
            <span class="custom-badge _red">
                  <span>{{ `${$t('aggregator')}: ${aggregator}` }} </span>
                </span>
            <span class="custom-badge _red">
                  <span>{{ `${$t('provider')}: ${getProviderName(provider)}` }} </span>
                </span>
            <span class="custom-badge _red">
                  <span>{{ `${$t('game_name')}: ${game}` }} </span>
                </span>
            <span class="custom-badge _red">
                  <span>{{ `${$t('currency')}: ${currency}` }} </span>
                </span>
          </div>
        </template>
    <DataTable :value="selectSession.items" 
    :rows="selectSession.size"
    :loading="loadingFlag || loadingLocalFlag" 
    :scrollable="true" 
      class="p-datatable-sm"
      >
      <template #empty>
       <div>{{ $i18n.t('sessions_not_found') }}</div> 
    </template>
      <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'min-width':'10rem'}">
        <template #body="{data}">
          
            <div>{{ new Date(data.created_at).toLocaleString() }}</div>
        </template>
      </Column>
      <Column field="game_session_id" :header="$t('game_session_id')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
        <template #body="{data}">
          
            <div>{{ data.game_session_id }}</div>
        </template>
      </Column>
      <Column field="amount" :header="$t('bet_amount')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
        <template #body="{data}">
          
            <div>{{ data.amount }}</div>
        </template>
      </Column>
      <Column field="type" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
        <template #body="{data}">
          
            <div>{{ $t(data.type)}}</div>
        </template>
      </Column>
      <!-- <Column field="currency" :header="$t('currency')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
        <template #body="{data}">
          
            <div>{{ data.currency }}</div>
        </template>
      </Column> -->
    </DataTable>
    <games-paggination 
                        :pagginationObject="filters" 
                        :requestData="selectSession" 
                        @apply-filters="setAndSubmitFilters" 
                        :isStatic="true"
                        />
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/components/api";
export default {
  name: "SelectSession",
  props:{
    sessionInfo: Object,
    provider: String,
    game: String,
    currency: String,
    aggregator: String,
    loadingFlag: Boolean,
  },
  data() {
    return {
      filters: {
        page: 1,
        game_session_id: '',
        size: 25,
      },
      loadingLocalFlag: false,
      startPage: 1,
      startSize: 25,
      currentLocale: this.$i18n.locale || 'en',
    }
  },
  async mounted() {
  },
  watch: {
    
},
  computed: {
    ...mapGetters({
      selectSession: 'getGameSession',
    }),
    
  },
  methods: {
    getProviderName(name){
      return name.replace(/_/g, ' ');
    },
    returnButton(){
      this.$store.commit('setShowSession', false)
    },
    async setAndSubmitFilters(){
        this.loadingLocalFlag = true;
        try {
          this.filters.game_session_id = this.sessionInfo._id
          await this.$store.dispatch('getGameSession', this.filters);
        } catch (error) {
          this.$toast.add({severity:'error', summary: this.$t('just_error'), life: 4000});
        } finally{
        this.loadingLocalFlag = false;
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.$store.commit('paggination/SET_LOADING_FLAG', false);
        }
      },
  }
}
</script>

<style lang="scss" scoped>
</style>
