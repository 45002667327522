<template>
    <Card v-if="player">
        <template #title>
          <Toolbar>
            <template #start>
                <h4>
                  {{ $t('bonuses_list') }}
                </h4>
                
            </template>
            <template #end>
              <Button :label="$t('add_bonus')"
              @click="addBonusModal = true" 
              :disabled="!allowedAddBonuses"
              v-tooltip.left="!allowedAddBonuses ? $t('no_permission') : $t('tooltip_add_bonus')" 
              icon="pi pi-gift"
              class="p-button-success p-button-sm ml-2" />
                
            </template>
        </Toolbar>
        </template>
        <template #content>
            <!-- :expandedRows.sync="expandedRows" 
            @row-collapse="onRowCollapse"  -->
            <!-- selectionMode="single"
             @row-select="selectRowTable"  -->
            <DataTable :value="playerBonuses" 
            responsiveLayout="scroll"
            :loading="loadingFlag"
            :expandedRows.sync="expandedPlayerBonuses"
            class="p-datatable-sm"
            >
            <template #empty>
                {{ $t('no_bonuses') }}
            </template>
              <!-- @row-unselect="onRowUnselect"  -->
    
    <Column :expander="true" :headerStyle="{'width': '1rem'}" />
    <Column field="name" :header="$t('name')" sortable :styles="{'width':'50%'}">
        <template #body="{data}">
            <b>{{$t(data.name)}}</b>
        </template>
    </Column>
    <Column field="quantity" :header="$t('quantity')" sortable :styles="{'width':'50%'}">
        <template #body="{data}">
            {{data.items.length}}
        </template>
    </Column>
    <template #expansion="slotProps">
        <div class="orders-subtable">
            <DataTable :value="slotProps.data.items">
                <Column field="type" :header="$t('bonus_type')" sortable :styles="{'width':'10rem'}">
                <template #body="{data}">
                    <div>
                        {{ $t(data.type) }}
                      </div>
                </template>
            </Column>
                <Column field="bonus_info" :header="$t('bonus_info')" sortable>
                <template #body="{data}">
                            <div v-if="data.bonus_type === 'free_rounds'">
                                {{ formatSpinsBonus(data) }}
                              </div>
                              <div v-if="data.bonus_type === 'deposit'">
                                {{ formatDepositBonus(data) }}
                              </div>
                </template>
            </Column>
                <Column field="created_at" :header="$t('added_at')" sortable :styles="{'width':'12rem'}">
                <template #body="{data}">
                            <div>
                                {{ new Date(data.created_at).toLocaleString() }}
                              </div>
                </template>
            </Column>
                <Column field="start_datetime" :header="$t('active_date')" sortable :styles="{'width':'12rem'}">
                <template #body="{data}">
                    <div v-if="data.start_datetime !== null">
                        {{ new Date(data.start_datetime).toLocaleString() }}
                      </div>
                </template>
            </Column>
                <Column field="wager_progress" :header="$t('wager_progress')" sortable :styles="{'width':'12rem'}">
                <template #body="{data}">
                            <div>
                                {{ data.wagering ? data.wagering : 0 }}
                              </div>
                </template>
            </Column>
                <Column :exportable="false" :styles="{'width':'5rem'}">
                <template #body="{data}">
                    <Button
                    icon="pi pi-trash"
                    @click="deletePlayerBonus(data)" 
                    :disabled="!allowedAddBonuses"
              v-tooltip.left="!allowedAddBonuses ? $t('no_permission') : $t('tooltip_delete_bonus')" 
                    class="p-button-danger p-button-sm" />
                </template>
            </Column>
            </DataTable>
        </div>
    </template>
</DataTable>
      </template>
      <template #footer>
        <v-dialog
        scrollable
        v-model="addBonusModal"
        max-width="500px"
        content-class="br-16"
        
    >
      <v-card>
        <v-card-title>
          <h4>{{ $i18n.t('addBonus') }}</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="addBonusModal = false">
            <v-icon color="var(--main-flamingo)">close</v-icon>
          </v-btn>
        </v-card-title>
        <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
          <v-card-text class="pb-0 pt-1 text-left space-y-2 form-block">
            <span>
              <h4 class="c-text white-space title-input">
                <p class="mb-0">{{ $t('select_deposit_bonus') }}:</p>
              </h4>
              <paggination-listbox
              v-model="selectedBonus.deposit"
              :options="listDepositBonuses"
              @start-loading="startDepositLoading"
              :maxWidthMenu="600"
              :loading="loadingDeposit"
              :loadingStart="startDepositLoadingFlag"
              @load-next-page="loadDepositNextPage"
              :placeholder="$t('select_deposit_bonus')"
              optionValue="value"
              optionLabel="text"
              ></paggination-listbox>
                        </span>
            <span>
              <h4 class="c-text white-space title-input">
                <p class="mb-0">{{ $t('select_freespins_bonus') }}:</p>
              </h4>
              <paggination-listbox
              v-model="selectedBonus.freespins"
              :options="listSpinsBonuses"
              @start-loading="startSpinsLoading"
              :maxWidthMenu="600"
              :loading="loadingSpins"
              :loadingStart="startSpinsLoadingFlag"
              @load-next-page="loadSpinsNextPage"
              :placeholder="$t('select_freespins_bonus')"
              optionValue="value"
              optionLabel="text"
              ></paggination-listbox>
                        </span>
          </v-card-text>
        </ValidationObserver>
        <v-card-actions>
          <v-row>
            <v-col>
              <Button class="p-button-sm p-button-success fw" icon="pi pi-save"
              @click="addBonus" :label="$i18n.t('add')" 
              :disabled="!selectedBonus.freespins && !selectedBonus.deposit"
                  :loading="loadingModalFlag"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </template>
      </Card>
</template>

<script>
import {mapGetters} from "vuex";
    export default {
        name: 'PPBonuses',
        props:{
        playerInfo: Object,
        },
        data() {
            return {
                loadingFlag: false,
                loadingModalFlag: false,
                addBonusModal: false,
                expandedPlayerBonuses: [],
                playerBonuses: [],
                depositMenu: false,
                selectedBonus: {
                  freespins: 0,
                  deposit: 0
                },
                listDepositBonuses: {
                  items: [],
                },
                listSpinsBonuses: {
                  items: [],
                },
                mergedBonuses:[],
                startDepositLoadingFlag: false,
                loadingDeposit: false,
                startSpinsLoadingFlag: false,
                loadingSpins: false,
                id: null,
            }
        },
        computed: {
            ...mapGetters({
      depositBonuses: 'bonuses/getDepositBonuses',
      spinsBonuses: 'bonuses/getSpinsBonuses',
      depositAllListBonuses: 'bonuses/getDepositAllListBonuses',
      spinsAllListBonuses: 'bonuses/getSpinsAllListBonuses',
      player: 'getSelectedPlayer',
      permissions: 'roles/elementsPlayerPermission',
    }),
            allowedAddBonuses() {
              if (this.permissions) {
                if (this.permissions.includes('add_bonuses') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            },
            spinsAvailableBonusesForPlayerCurrency() {
              if (this.spinsBonuses.items.length === 0) {
                  return [];
                }
            return this.spinsBonuses.items.filter(bonus => bonus.currency_bet[this.player.currency]);
            },
              depositAvailableBonusesForPlayerCurrency() {
                if (this.depositBonuses.items.length === 0) {
                  return [];
                }
              return this.depositBonuses.items.filter(bonus => bonus.currency_limits[this.player.currency]);
            },
        },
        async mounted() {
            this.loadingFlag = true;
        if (Object.keys(this.player).length !== 0) {
            this.id = this.player.id;
            this.playerBonusesList();
            
        }
        this.loadingFlag = false;
    },
       
        methods: {
          async startDepositLoading() {
            this.startDepositLoadingFlag = true;
            if (this.depositBonuses.items.length === 0) {
            await this.getDepositBonuses();
            }
            this.startDepositLoadingFlag = false;
          },
          async startSpinsLoading() {
            this.startSpinsLoadingFlag = true;
            if (this.spinsBonuses.items.length === 0) {
            await this.getSpinsBonuses();
            }
            this.startSpinsLoadingFlag = false;
          },
          //========================================================================================================================================================
          fullFormatedSpinsBonuses() {
            const { page, pages, size, total } = this.spinsAllListBonuses;
            const items = this.listSpinsBonuses = [
              { text: this.$t('none'), value: 0 }, // "None"
              ...this.spinsAvailableBonusesForPlayerCurrency.map(bonus => ({
                text: this.formatSpinsBonus(bonus),
                value: bonus.id
              }))
        ]
            this.listSpinsBonuses = {
              items,
              page,
              pages,
              size,
              total
            };
          },
          fullFormatedDepositBonuses() {
            const { page, pages, size, total } = this.depositAllListBonuses;

            const items = [
              { text: this.$t('none'), value: 0 },
              ...this.depositAvailableBonusesForPlayerCurrency.map(bonus => ({
                text: this.formatDepositBonus(bonus),
                value: bonus.id
              }))
            ];
           
            this.listDepositBonuses = {
              items,
              page,
              pages,
              size,
              total
            };

        
          },
          //========================================================================================================================================================
    async loadDepositNextPage() {
      this.loadingDeposit = true;
      await this.getDepositBonuses('one-more');
      this.loadingDeposit = false;
    },
    async loadSpinsNextPage() {
      this.loadingSpins = true;
      await this.getSpinsBonuses('one-more');
      this.loadingSpins = false;
    },
    //========================================================================================================================================================
    
    async getDepositBonuses(condition) {
      try {
      let filters = {
        page: 1,
        size: 50,
      }
      if (condition === 'one-more') {
        filters.page = this.listDepositBonuses.page + 1
      }
      await this.$store.dispatch('bonuses/awaitGetDepositBonuses', filters);
      this.fullFormatedDepositBonuses();
      } catch (error) {
        this.$toast.add({
          severity: 'error', summary: this.$t('just_error'), 
          life: 4000, detail: error
        })
      } finally{
      }
    },
    async getSpinsBonuses(condition) {
      let filters = {
        page: 1,
        size: 50,
      }
      if (condition === 'one-more') {
        filters.page = this.listSpinsBonuses.page + 1
      }
      try {
        await this.$store.dispatch('bonuses/awaitGetSpinsBonuses', filters);
        this.fullFormatedSpinsBonuses();
      } catch (error) {
        this.$toast.add({
          severity: 'error', summary: this.$t('just_error'), 
          life: 4000, detail: error
        })
      } finally{
      }
    },
    //========================================================================================================================================================
    
      formatDepositBonus(bonus){
        const formattedBonus = Object.entries(bonus.currency_limits)
            .map(([currency, limits]) => ` ${currency}: ${this.$t('min_dep')}: ${limits.min_dep}, ${this.$t('max_dep')}: ${limits.max_dep}`)
        const restOfName = [
          this.$t('bonus_percent') + ': ' + bonus.bonus_percent,
          this.$t('wager') + ': ' + bonus.wager,
          this.$t('type') + ': ' + this.$t(bonus.type),
        ];

        return [...restOfName, ...formattedBonus].join(', ');
      },
        formatSpinsBonus(bonus) {
        const formattedBonus = Object.entries(bonus.currency_bet)
          .filter(([currency, value]) => value !== 0 )
          .map(([currency, value]) => this.$t('bet') + ': ' + value + ' ' + currency);

        const restOfName = [
          this.$t('game_name') + ': ' + bonus.game_name,
          this.$t('provider') + ': ' + bonus.provider_name,
          this.$t('spin') + ': ' + bonus.count,
        ];
        const restOfLast = [
          this.$t('wager') + ': ' + bonus.wager,
          this.$t('type') + ': ' + this.$t(bonus.type),
          
        ];

        return [...restOfName, ...restOfLast, ...formattedBonus].join(', ');
      },
      //========================================================================================================================================================
      
      async playerBonusesList(){
        const bonuses = await this.$store.dispatch('getPlayerBonuses', { user_id: this.id });
        // console.log(bonuses);
        this.playerBonuses = [];

                for (const key in bonuses) {
          if (bonuses.hasOwnProperty(key)) {
            let bonusType;
            if (key === 'deposit') {
              bonusType = 'deposit';
            } else {
              bonusType = 'free_rounds';
            }
            
            this.playerBonuses.push({
              name: key,
              items: bonuses[key].map(item => ({ ...item, bonus_type: bonusType }))
            });
          }
        }
    //     this.mergedBonuses = [
    //   ...this.playerBonuses.deposit.map(bonus => ({ ...bonus, bonus_type: 'deposit' })),
    //   ...this.playerBonuses.free_rounds.map(bonus => ({ ...bonus, bonus_type: 'free_rounds' }))
    //   ];
    },
    async deletePlayerBonus(bonus) {
      this.loading = true;
      const [success, error] = await this.$store.dispatch(
          'bonuses/deletePlayerBonus',
          bonus.id,
      );
      const type = this.$t(bonus.type);
      if (success) {
        this.$toast.add({
            severity: 'success', summary: `${this.$t('bonus_player_deleted')}: ${type}`, life: 4000
          })
        this.playerBonusesList();
      } else {
        if (error === "Can't remove active bonus from user") {
          this.$toast.add({
            severity: 'error', summary: this.$t('bonus_player_cant_deleted'), life: 4000
          })
        }
      }
      this.loading = false;
      this.loadingModalFlag = false;
    },
    async addBonus() {
      this.loadingModalFlag = true;
      //add bonus selectedBonus
      const userData = {
  dep_bonus_id: this.selectedBonus.deposit !== 0 ? this.selectedBonus.deposit : null,
  free_rounds_bonus_id: this.selectedBonus.freespins !== 0 ? this.selectedBonus.freespins : null,
  user_id: this.id,
};
      const [success, error] = await this.$store.dispatch(
        'bonuses/addBonusToUser',
            userData
            );
      if (success) {
        this.$toast.add({
            severity: 'success', summary: this.$t('added_bonus_success'), life: 4000
          })
        this.playerBonusesList();
        this.addBonusModal = false;   
        } else if(error === 400){
          this.$toast.add({
            severity: 'error', summary: this.$t('user_have_bonus_error'), life: 4000
          })
        } else{
        this.$toast.add({
            severity: 'error', summary: this.$t('added_bonus_error'), life: 4000
          })
        }
        this.selectedBonus = {
          deposit: 0,
          freespins: 0
        }; 
        this.loadingModalFlag = false;
    },
    // NO Useful method for player bonuses table
//        selectRowTable(col) {
//    const data = col.data;
//    console.log('data', data);
//    const index = this.expandedPlayerBonuses.findIndex(row => row.id === data.id);
//    console.log('index', index);

//    if (index !== -1) {
//      this.expandedPlayerBonuses.splice(index, 1);
//    } else {
//      this.expandedPlayerBonuses.push(data);
//    }
//  },
        },
    }
</script>

<style lang="scss" scoped>

</style>