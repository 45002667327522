<template>
    <Card class="card-custom" style="height: 100%">
        <template #title>
          <h4>{{ $i18n.t('payment_info') }}</h4>
        </template>
        <template #content>
          <v-row align="center">
            <v-col class="text-left">
              {{ $i18n.t('total_balance') }}:
            </v-col>
            <v-col class="text-right">
              {{ 
                (player.balance ? player.balance : 0) 
              }} {{ player.currency }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="text-left">
              {{ $i18n.t('casino_revenue') }}:
            </v-col>
            <v-col class="text-right">
              {{
                (player.casino_revenue ? player.casino_revenue : 0)
              }} 
                 {{ player.currency }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="text-left">
              {{ $i18n.t('cashout_balance') }}:
            </v-col>
            <v-col class="text-right">
              
              {{ player && player.sum_available_for_withdraw && player.sum_available_for_withdraw !== null  ? player.sum_available_for_withdraw : 0 }} {{ player.currency }}
            </v-col>
          </v-row>
          <v-row align="center" v-if="!isAgentPlayer">
            <v-col class="text-left">
              {{ $t('bonus_balance') }}:
            </v-col>
            <v-col class="text-right">

              {{ player.bonus_balance ? player.bonus_balance : 0 }} {{ player.currency }}
            </v-col>
          </v-row>
          <v-row align="center" v-if="!isAgentPlayer">
            <v-col class="text-left">
              {{ $i18n.t('wager_playthrough') }}:
            </v-col>
            <v-col class="justify-end d-flex align-center">
              <span class="d-flex align-center">
                <span>{{ player.wagering
                  && player.wagering !== null ? player.wagering : 0 }}</span>
                <span class="ml-1">{{ player.currency }}</span>

              </span>
              <span class="mx-1">/</span>
              <span class="d-flex align-center">
                <span>{{ player && player.wager_amount !== null ? player.wager_amount : 0 }}</span>
                <span class="ml-1">{{ player.currency }}</span>
              </span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="text-left">
              {{ $i18n.t('deposits') }}:
            </v-col>
            <v-col class="text-right" style="color: var(--green-500)">
              {{ player && player.deposits_sum
                && player.deposits_sum
                !== null && player.deposits_sum
                !== 0 ? player.deposits_sum
                : 0 }} {{ player.currency }}
            </v-col>
          </v-row>
            <v-badge
      bordered
      color="error"
      icon="mdi-lock"
      overlap
      :value="successWithdrawBlock"
      class="d-block"
      :class="{'mt-2' : successWithdrawBlock}"
    > 
            <v-row align="center" :class="{'_blocked' : successWithdrawBlock}">
              <v-col class="text-left">
                {{ $i18n.t('withdrawals') }}:
              </v-col>
              <v-col class="text-right" style="color: var(--red-500)">
                {{ player && player.withdraws_sum
                  && player.withdraws_sum
                  !== null && player.withdraws_sum
                  !== 0 ? player.withdraws_sum
                  : 0 }} {{ player.currency }}
                
              </v-col>
            </v-row>
          </v-badge>
        </template>
        <template #footer>
          <v-row v-if="!isAgentPlayer">
            <v-col class="">
              <Button :disabled="!allowedBlockWithdraw" 
              v-tooltip.top="!allowedBlockWithdraw ? $t('no_permission') : $t('tooltip_block_withdraw')" 
              :label="$t('block_withdraw')" v-if="!successWithdrawBlock" @click="blockPlayerWithdraw(true)"
                         class="p-button-danger p-button-sm" />
              <Button :disabled="!allowedBlockWithdraw" 
              :label="$t('unblock_withdraw')" 
              v-if="successWithdrawBlock" 
              @click="blockPlayerWithdraw(false)"
              v-tooltip.top="!allowedBlockWithdraw ? $t('no_permission') : $t('tooltip_unblock_withdraw')"
                          class="p-button-success p-button-sm" />
          </v-col>
        </v-row>
      </template>
    </Card>
</template>

<script>
import {mapGetters} from 'vuex';
import Api from "@/components/api";
    export default {
        name: 'PlayerPaymentsCard',
        props: {
            player: {
                type: Object,
                required: true,
            },
            isAgentPlayer: {
              type : Boolean,
            }
        },
        computed: {
            ...mapGetters({
              permissions: 'roles/elementsPlayerPermission',
            }),
            allowedBlockWithdraw() {
              if (this.permissions) {
                if (this.permissions.includes('block_withdraw') || this.permissions.includes('all')) {
                  return true;
                } else{
                  return false;
                }
              } return false;
            }
        },
        data() {
            return {
                successWithdrawBlock: false,
            }
        },
        mounted() {
            
        },
        watch:{
            'player.processing'(oldValue, newValue){
                if (newValue) {
                    this.successWithdrawBlock = this.player.processing;
                }
            },
        },
        methods: {
            async blockPlayerWithdraw(shouldBlockPlayerWithdraw) {
              this.$emit('loading-flag')
              let res = await Api.blockPlayerWithdraw(this.player.id, shouldBlockPlayerWithdraw);
              if (res) {
                this.successWithdrawBlock = !this.successWithdrawBlock;
                //await this.$store.dispatch('playerInfo', {id: this.id});
            
                if(this.successWithdrawBlock){
                this.$toast.add({
                    severity: 'success', summary: this.$t('withdraw_blocked'), life: 4000
                  })
                } else{
                  this.$toast.add({
                    severity: 'info', summary: this.$t('withdraw_unblocked'), life: 4000
                  })
                }
              } else {
                this.$toast.add({
                    severity: 'error', summary: this.$t('withdraw_block_failed'), life: 4000
                  })
              }
              this.$emit('loading-flag')
            }
        },
    }
</script>

<style lang="scss" scoped>
._blocked{
    padding: 4px;
    border: 1px solid var(--red-500);
  }
</style>