<template>
  <div>
    <div>
      <v-card class="mt-2 elevation-2">
      <v-card-title class="pb-0">
        <h3> {{ $i18n.t('affs_search') }}</h3>
      </v-card-title>
      <v-card-text class="pa-3">
        <v-row>
          <v-col xl="2" md="2" sm="6" cols="12" class="col" style="max-width: 90px;">
            <v-text-field label="ID" outlined dense hide-details color="var(--main-flamingo)" v-model="filters.affiliate" ></v-text-field>
          </v-col>
          <v-col xl="2" md="2" sm="6" cols="12" class="col" style="max-width: 120px;">
            <v-text-field label="E-mail" outlined dense hide-details color="var(--main-flamingo)" v-model="filters.email" ></v-text-field>
          </v-col>
          <v-col xl="2" md="2" sm="6" cols="12" class="col" style="max-width: 120px;">
            <v-text-field label="Telegram" outlined dense hide-details color="var(--main-flamingo)" v-model="filters.telegram" ></v-text-field>
          </v-col>
          <!-- <v-col xl="2" md="2" sm="6" cols="12" class="col" >
            <v-text-field label="Skype" outlined dense hide-details color="var(--main-flamingo)" v-model="filters.skype" ></v-text-field>
          </v-col> -->
          <v-col xl="2" md="2" sm="6" cols="12" class="col " style="max-width: 220px;">
            <!-- <v-text-field
                type="date"
                :prefix="$i18n.t('from')"
                :label="$i18n.t('date')"
                outlined dense hide-details color="var(--main-flamingo)"
                v-model="filters.min_reg_date"
            ></v-text-field> -->
            <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div
              class="input-menu"
              v-on="on"
              >
              {{ 
                (datesToFilter === 0 || datesToFilter == '' || datesToFilter === null) ? 
                $t('date')
                : $t('from') + ' ' + datesToFilter[0] + ' ' + $t('to') + ' ' + (datesToFilter[1] !== undefined ? datesToFilter[1] : '')
              }}
              <v-icon>event</v-icon>
            </div>
            
            </template>
        
            <v-date-picker
              v-model="datesToFilter"
              range
              no-title
              :locale="currentLocale"
              color="var(--main-flamingo)"
              content-class="date"
            ></v-date-picker>
          </v-menu>
          </v-col>
          <!-- <v-col xl="2" md="2" sm="6" cols="12" class="col" style="max-width: 185px;">
            <v-text-field
            outlined dense hide-details color="var(--main-flamingo)"
                type="date"
                :label="$i18n.t('date')" :prefix="$i18n.t('to')" v-model="filters.max_reg_date" ></v-text-field>
          </v-col> -->
          <v-col class="text-left d-flex align-center">
            <v-btn @click="clearFilters" class="bg-c-main-flamingo c-white mr-2"  style="text-transform: none" >
              <v-icon color="white">
                delete
              </v-icon>
              <!-- {{ $i18n.t('clear') }} -->
            </v-btn>
            <v-btn size="lg" @click="applyFilters" color="bg-c-red c-white" style="text-transform: none">
              <v-icon>search</v-icon>
              <!-- {{ $i18n.t('find') }} -->
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AffiliatesTab",

    data(){
      return{
        fields: [
          { key: 'spinId', label: 'ID', sortable: false },
          { key: 'game', label:  this.$i18n.t('game'), sortable: false },
          { key: 'date', label:  this.$i18n.t('session_start'), sortable: false },
          { key: 'provider', label:  this.$i18n.t('provider'), sortable: false },
          { key: 'result', label:  this.$i18n.t('result'), sortable: false }
        ],
        typeOptions:[{value: 0, text:  this.$i18n.t('win')},{value: 1, text:  this.$i18n.t('loss')}],
        providerOptions:[],
        dateMenu: false,
        datesToFilter: [],
        currentLocale: this.$i18n.locale || 'en',
        filters:{
          affiliate_id: '',
          email: '',
          min_reg_date:'',
          max_reg_date:'',
          telegram: '',
          skype: '',
        },
        loadingFlag: false,

      }
    },
    /*
    id - айди юзера
provider - название провайдера игры (список заранее задан для каждого юзера, сейчас делаю запрос)
type - значения возможнве: 0 - означает спины выигрышные, 1 - проигрышные спины (со знаком минуса идут)
minDate - дата от
maxDate - дата до
    * */
    async mounted(){
      await this.applyFilters()
    },
    methods:{
      async applyFilters(){
        this.loadingFlag = true
        //getAffiliates
        if (this.datesToFilter.length === 2) {
        // Если обе даты выбраны
        this.filters.min_reg_date = this.datesToFilter[0];
        this.filters.max_reg_date = this.datesToFilter[1];
      } else if (this.datesToFilter.length === 1) {
        // Если выбрана только одна дата
        this.filters.min_reg_date = this.datesToFilter[0];
      } 
        await this.$store.dispatch('getAffiliates', {filters: this.filters});
        this.loadingFlag = false
      },
      async clearFilters(){
        Object.keys(this.filters).forEach((key, index) =>{
          this.filters[key] = ''
        })
        this.datesToFilter = [];
      }
    }
  }
</script>

<style lang="scss" scoped>
.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
</style>