<template>
  <div>
      <DataTable 
      :value="compensations" 
      data-key="id"
      :rows="10"
      :loading="loadingFlag" 
      :paginator="true"
      :scrollable="true" 
        class="p-datatable-sm"
        >
        <template #empty>
         <div class="font-weight-bold">{{ $i18n.t('player_balance_corrections_not_found') }} </div>
      </template>
        <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'max-width':'25rem'}">
          <template #body="{data}">
            <div style="white-space: nowrap">
              {{ new Date(data.created_at).toLocaleString() }}
            </div>
        </template>
        </Column>
        <Column field="admin" :header="$t('admin')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <v-chip small label color="var(--red)">
              <span class="c-white">{{ data.admin }}</span>
            </v-chip>
        </template>
        <template #filter>
        </template>
        </Column>
        <Column field="log_type" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div>
              {{ $t(data.log_type) }}
            </div>
        </template>
        </Column>
        <Column field="amount" :header="$t('amount')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div>
              {{ data.amount }}
            </div>
        </template>
        </Column>
        <Column field="text" :header="$t('comments')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <div>
              {{ data.text }}
            </div>
        </template>
        </Column>
      </DataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
  export default {
    name: "BalanceCorrectionsTab",
    props: {
      playerId: {
        required: true,
        default: null,
      },
      loadingTrigger:{
        type: Boolean,
        default: false
      }
    },
    data(){
      return{
        loadingFlag: false,
      }
    },
    watch: {
      loadingTrigger(val){
        this.getCompensation()
      },
    },
    async mounted(){
      this.getCompensation()
    },
    computed:{
      ...mapGetters({
        compensations: 'getCompensation',
      })
    },
    methods:{
      async getCompensation(){
        this.loadingFlag = true;
        try {
          
          await this.$store.dispatch('awaitGetCompensation', {id: this.playerId});
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
      } finally{

        this.loadingFlag = false;
      }
      },
    },
  }
</script>

<style scoped>

</style>
