<template>
  <div>
    <v-card class="card_style" v-if="false" style="">
      <v-card-text class="card_body_style">
        <v-row>
          <v-col cols="5" class="label_style">
            {{ $i18n.t('search_by') }}
          </v-col>
          <v-col cols="4" class="label_style">
            {{ $i18n.t('games') }}
          </v-col>
          <v-col cols="3" class="label_style_center">
            {{ $i18n.t('providers') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-text-field disabled ></v-text-field>
          </v-col>
          <v-col cols="4">
            <b-form-select disabled :options="[{value: 0, text: $i18n.t('option0')},{value: 1, text: $i18n.t('option1')}]"></b-form-select>
          </v-col>
          <v-col>
            <b-form-select  :options="$store.getters.getPlayerProviders" v-model="filters.provider"></b-form-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="label_style">
            {{ $i18n.t('type') }}
          </v-col >
          <v-col cols="2" class="label_style_center">
            {{ $i18n.t('currency') }}
          </v-col>
          <v-col cols="7" class="label_style_center" style="text-align: center">
            {{ $i18n.t('activity_period') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <b-form-select  :options="typeOptions" v-model="filters.type"></b-form-select>
          </v-col>
          <v-col cols="2">
            <b-form-select disabled :options="[{value: 0, text: $i18n.t('option0')},{value: 1, text: $i18n.t('option1')}]"></b-form-select>
          </v-col>
          <v-col >
            <b-input-group size="md">
              <template v-slot:append>
                <b-icon-calendar class="h1"></b-icon-calendar>
              </template>
              <template v-slot:prepend>
                <div style="padding: 8px">
                  {{ $i18n.t('from') }}
                </div>
              </template>
              <v-text-field type="date" v-model="filters.minDate"></v-text-field>
            </b-input-group>
          </v-col>
          <v-col >
            <b-input-group size="md">
              <template v-slot:prepend>
                <div style="padding: 8px">
                  {{ $i18n.t('to') }}
                </div>
              </template>
              <template v-slot:append>
                <b-icon-calendar class="h1"></b-icon-calendar>
              </template>
              <v-text-field type="date" v-model="filters.maxDate" ></v-text-field>
            </b-input-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-footer style="text-align: right; padding-bottom: 0px">
        <v-row style="align-content: space-between">
          <v-col>
            <v-btn size="lg" @click="clearFilters">{{ $i18n.t('clear') }}</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn size="lg" @click="applyFilters">{{ $i18n.t('applyLabel') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-footer>
    </v-card>
  </div>
</template>


<script>
  export default {
    name: "OffersTab",

    data(){
      return{
        fields: [
          { key: 'spinId', label:  this.$i18n.t('id'), sortable: false },
          { key: 'game', label: this.$i18n.t('game'), sortable: false },
          { key: 'date', label: this.$i18n.t('session_start'), sortable: false },
          { key: 'provider', label: this.$i18n.t('provider'), sortable: false },
          { key: 'result', label:  this.$i18n.t('result'), sortable: false }
        ],
        typeOptions:[{value: 0, text: this.$i18n.t('win')},{value: 1, text: this.$i18n.t('loss')}],
        providerOptions:[],
        filters:{
          type: '',
          provider: '',
          minDate:'',
          maxDate:'',
        },
        loadingFlag: false,

      }
    },
    /*
    id - айди юзера
provider - название провайдера игры (список заранее задан для каждого юзера, сейчас делаю запрос)
type - значения возможнве: 0 - означает спины выигрышные, 1 - проигрышные спины (со знаком минуса идут)
minDate - дата от
maxDate - дата до
    * */
    async mounted(){

    },
    methods:{
      async applyFilters(){

      },
      async clearFilters(){

        //await this.$store.dispatch('playersFilters', {filters: this.filters});
      }
    }
  }
</script>

<style scoped>

</style>
